import { commonPatterns } from './eyeTrackingPatterns';

// Typowe elementy interfejsu i ich wagi uwagi
const interfaceElements = {
  header: { weight: 0.9, yRange: [0, 15] },
  navigation: { weight: 0.8, yRange: [5, 20] },
  mainContent: { weight: 0.7, yRange: [20, 60] },
  sidebar: { weight: 0.5, xRange: [75, 95], yRange: [20, 80] },
  footer: { weight: 0.3, yRange: [85, 95] }
};

// Symulacja zachowań użytkownika
const userBehaviors = {
  scanning: {
    speed: 0.3, // sekund na punkt
    focusIntensity: 0.7,
    pattern: 'zigzag'
  },
  reading: {
    speed: 0.8,
    focusIntensity: 0.9,
    pattern: 'linear'
  },
  searching: {
    speed: 0.2,
    focusIntensity: 0.6,
    pattern: 'scattered'
  }
};

export const generateRealisticEyeTrackingData = (
  width, 
  height, 
  contentType = 'article',
  duration = 10000 // ms
) => {
  const points = [];
  const timestamp = Date.now();
  
  // Wybierz odpowiedni wzorzec na podstawie typu contentu
  const pattern = selectPatternForContent(contentType);
  
  // Symuluj ruch oka
  let currentTime = 0;
  while (currentTime < duration) {
    const position = calculateNextPosition(
      pattern, 
      currentTime, 
      width, 
      height, 
      contentType
    );
    
    points.push({
      x: position.x,
      y: position.y,
      value: calculateIntensity(position, contentType),
      timestamp: timestamp + currentTime
    });

    currentTime += getTimeIncrement(contentType);
  }

  return normalizeData(points, width, height);
};

const selectPatternForContent = (contentType) => {
  const patterns = {
    article: 'F-Pattern',
    landing: 'Z-Pattern',
    search: 'Scattered',
    product: 'Gutenberg'
  };

  return commonPatterns.find(p => p.name === patterns[contentType]) || commonPatterns[0];
};

const calculateNextPosition = (pattern, time, width, height, contentType) => {
  // Bazowa pozycja z wzorca
  const basePosition = getBasePosition(pattern, time);
  
  // Dodaj naturalne odchylenia
  const noise = getNaturalNoise();
  
  // Uwzględnij specyfikę contentu
  const contentBias = getContentTypeBias(contentType);
  
  return {
    x: (basePosition.x + noise.x + contentBias.x) * width,
    y: (basePosition.y + noise.y + contentBias.y) * height
  };
};

const getNaturalNoise = () => ({
  x: (Math.random() - 0.5) * 0.1, // ±5% odchylenia
  y: (Math.random() - 0.5) * 0.1
});

const getContentTypeBias = (contentType) => {
  const biases = {
    article: { x: 0, y: 0.1 },  // Większa uwaga na górze
    landing: { x: 0, y: 0 },    // Równomierne rozłożenie
    search: { x: 0.1, y: 0 },   // Większa uwaga po prawej (wyniki)
    product: { x: -0.1, y: 0 }  // Większa uwaga po lewej (zdjęcia)
  };
  
  return biases[contentType] || { x: 0, y: 0 };
};

const calculateIntensity = (position, contentType) => {
  let baseIntensity = 0.5;
  
  // Sprawdź czy pozycja jest w ważnym obszarze
  Object.entries(interfaceElements).forEach(([element, config]) => {
    if (isInRange(position, config)) {
      baseIntensity = Math.max(baseIntensity, config.weight);
    }
  });
  
  // Dodaj naturalne fluktuacje
  const fluctuation = Math.random() * 0.2 - 0.1; // ±10%
  
  return Math.min(1, Math.max(0, baseIntensity + fluctuation));
};

const isInRange = (position, config) => {
  const xInRange = !config.xRange || 
    (position.x >= config.xRange[0] && position.x <= config.xRange[1]);
  const yInRange = !config.yRange || 
    (position.y >= config.yRange[0] && position.y <= config.yRange[1]);
  
  return xInRange && yInRange;
};

const getTimeIncrement = (contentType) => {
  const baseIncrement = 50; // ms
  const typeMultipliers = {
    article: 1.5,  // Wolniejsze czytanie
    landing: 0.8,  // Szybsze skanowanie
    search: 0.6,   // Bardzo szybkie skanowanie
    product: 1.2   // Umiarkowane tempo
  };
  
  return baseIncrement * (typeMultipliers[contentType] || 1);
};

const normalizeData = (points, width, height) => {
  // Normalizuj wartości do zakresu heatmapy
  const maxValue = Math.max(...points.map(p => p.value));
  
  return points.map(point => ({
    x: Math.round(point.x),
    y: Math.round(point.y),
    value: Math.round((point.value / maxValue) * 100),
    timestamp: point.timestamp
  }));
};

const getBasePosition = (pattern, time) => {
  const patternDuration = 10000; // 10 sekund na pełny wzorzec
  const progress = (time % patternDuration) / patternDuration;
  
  switch (pattern.name) {
    case 'F-Pattern':
      return getFPatternPosition(progress);
    case 'Z-Pattern':
      return getZPatternPosition(progress);
    case 'Gutenberg-Pattern':
      return getGutenbergPosition(progress);
    default:
      return getDefaultPosition(progress);
  }
};

const getFPatternPosition = (progress) => {
  if (progress < 0.3) {
    // Górna linia
    return {
      x: progress * 3.33,
      y: 0.1
    };
  } else if (progress < 0.5) {
    // Pierwsza środkowa linia
    return {
      x: (progress - 0.3) * 2.5,
      y: 0.4
    };
  } else if (progress < 0.7) {
    // Druga środkowa linia
    return {
      x: (progress - 0.5) * 2.5,
      y: 0.7
    };
  } else {
    // Skanowanie lewej strony
    return {
      x: 0.1,
      y: (progress - 0.7) * 3.33
    };
  }
};

const getZPatternPosition = (progress) => {
  if (progress < 0.3) {
    // Górna linia
    return {
      x: progress * 3.33,
      y: 0.1
    };
  } else if (progress < 0.7) {
    // Ukośna linia
    const diagonalProgress = (progress - 0.3) / 0.4;
    return {
      x: diagonalProgress,
      y: diagonalProgress
    };
  } else {
    // Dolna linia
    return {
      x: (1 - (progress - 0.7) * 3.33),
      y: 0.9
    };
  }
};

const getGutenbergPosition = (progress) => {
  if (progress < 0.25) {
    // Lewy górny róg (primary optical area)
    return {
      x: 0.1 + (progress * 0.2),
      y: 0.1 + (progress * 0.2)
    };
  } else if (progress < 0.5) {
    // Prawy górny róg (strong fallow area)
    return {
      x: 0.7 + ((progress - 0.25) * 0.2),
      y: 0.1 + ((progress - 0.25) * 0.2)
    };
  } else if (progress < 0.75) {
    // Lewy dolny róg (weak fallow area)
    return {
      x: 0.1 + ((progress - 0.5) * 0.2),
      y: 0.7 + ((progress - 0.5) * 0.2)
    };
  } else {
    // Prawy dolny róg (terminal area)
    return {
      x: 0.7 + ((progress - 0.75) * 0.2),
      y: 0.7 + ((progress - 0.75) * 0.2)
    };
  }
};

const getDefaultPosition = (progress) => {
  // Domyślny wzorzec - spirala od środka
  const angle = progress * Math.PI * 10;
  const radius = progress * 0.4;
  return {
    x: 0.5 + Math.cos(angle) * radius,
    y: 0.5 + Math.sin(angle) * radius
  };
};