import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Snackbar from '../Atoms/snackbar';
import { format, parseISO } from 'date-fns';
import { 
  DIRECTUS_PERSONAS_ENDPOINT, 
  DIRECTUS_USER_JOURNEYS_ENDPOINT,
  DIRECTUS_HEATMAPS_ENDPOINT,
  DIRECTUS_INSTANCE
} from '../../api';

import {
  CloudArrowUpIcon,
  ArrowLeftIcon,
  EllipsisVerticalIcon,
  FaceSmileIcon,
  PhotoIcon,
  FolderArrowDownIcon,
  TrashIcon,
  ArrowPathIcon,
  PresentationChartBarIcon,
} from '@heroicons/react/24/outline';

const DIRECTUS_LINKED_USER_JOURNEYS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/linked_user_journeys`;
const DIRECTUS_LINKED_HEATMAPS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/linked_heatmaps`;

function PersonasDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [personas, setPersonas] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [actionName, setActionName] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const token = localStorage.getItem('directus_token');
  const autosaveTimeoutRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userJourneys, setUserJourneys] = useState([]);
  const [heatmaps, setHeatmaps] = useState([]);

  const editorRefs = useRef({
    biography: null,
    motivations: null,
    needs: null,
    frustrations: null,
  });

  const goBack = () => {
    navigate('/personas');
  };

  const fetchPersonas = async () => {
    try {
      const response = await axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          fields: [
            '*',
            'project.project_name',
            'linked_user_journeys.user_journey_id.user_journey_name'
          ]
        }
      });

      console.log("Raw data:", response.data);

      const data = response.data.data;
      setPersonas(data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching persona details:', error);
      setError('Failed to fetch persona details.');
      setLoading(false);
    }
  };

  const initializeEditors = useCallback(() => {
    ['biography', 'motivations', 'needs', 'frustrations'].forEach((field) => {
      if (!editorRefs.current[field]) {
        const editorData = typeof personas[field] === 'string' ? JSON.parse(personas[field]) : personas[field];
        editorRefs.current[field] = new EditorJS({
          holder: `${field}-editor`,
          data: editorData,
          minHeight: 0,
          tools: {
            header: {
              class: Header,
              inlineToolbar: ['link'],
              config: {
                placeholder: 'Enter a header',
                levels: [2, 3],
                defaultLevel: 2
              }
            },
            paragraph: {
              class: Paragraph,
              inlineToolbar: true
            },
            list: {
              class: List,
              inlineToolbar: true
            },
          },
          onReady: () => {
            console.log(`${field} editor is ready`);
          },
          onChange: () => {
            if (autosaveTimeoutRef.current) {
              clearTimeout(autosaveTimeoutRef.current);
            }
            autosaveTimeoutRef.current = setTimeout(() => handleEditorChange(field), 2000);
          }
        });
      }
    });
  }, [personas]);

  useEffect(() => {
    fetchPersonas();
  }, [id, token]);

  useEffect(() => {
    if (personas) {
      initializeEditors();
    }
  }, [personas, initializeEditors]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [journeyResponse, heatmapsResponse] = await Promise.all([
          axios.get(`${DIRECTUS_USER_JOURNEYS_ENDPOINT}`, {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`${DIRECTUS_HEATMAPS_ENDPOINT}`, {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);
        setUserJourneys(journeyResponse.data.data);
        setHeatmaps(heatmapsResponse.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token]);

  const handleEditorChange = async (field) => {
    if (editorRefs.current[field]) {
      try {
        const outputData = await editorRefs.current[field].save();
        const updatedPersonas = {
          ...personas,
          [field]: JSON.stringify(outputData),
          date_updated: new Date().toISOString()
        };
        setPersonas(updatedPersonas);
        await savePersonaData(updatedPersonas);
      } catch (error) {
        console.error(`Error saving editor content for ${field}:`, error);
      }
    }
  };

  const savePersonaData = async (dataToSave) => {
    try {
      const response = await axios.patch(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, dataToSave, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      console.log("Received response:", response.data);

      if (response.status === 200) {
        setSnackbarMessage('Persona has been saved properly (remove it later)');
        setSnackbarType('success');
        setShowSnackbar(true);
        setTimeout(() => setShowSnackbar(false), 3000);
      } else {
        console.error('Unexpected status code:', response.status);
        alert('Failed to update persona: Unexpected status code');
      }
    } catch (error) {
      console.error('Error updating persona:', error);
      alert(`Failed to update persona: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const deletePersona = () => {
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/personas');
    } catch (error) {
      console.error('Error deleting persona:', error);
      setSnackbarMessage('Failed to delete persona');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
    setShowDeleteModal(false);
  };

  const handleUserJourneyChange = async (event) => {
    const value = event.target.value;
    if (value === 'new') {
      navigate('/user-journeys/create');
    } else if (value) {
      try {
        await axios.post(DIRECTUS_LINKED_USER_JOURNEYS_ENDPOINT, 
          { 
            persona_id: id,
            user_journey_id: value 
          },
          { 
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            } 
          }
        );
        await fetchPersonas(); // Refresh data
        setSnackbarMessage('User Journey linked successfully');
        setSnackbarType('success');
        setShowSnackbar(true);
      } catch (error) {
        console.error('Error linking user journey:', error);
        console.log('Error details:', error.response?.data);
        setSnackbarMessage('Failed to link User Journey');
        setSnackbarType('error');
        setShowSnackbar(true);
      }
    }
  };

  const handleHeatmapChange = async (event) => {
    const value = event.target.value;
    if (value === 'new') {
      navigate('/heatmaps/create');
    } else if (value) {
      try {
        await axios.post(DIRECTUS_LINKED_HEATMAPS_ENDPOINT, 
          { 
            persona_id: id,
            heatmap_id: value 
          },
          { 
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            } 
          }
        );
        await fetchPersonas(); // Refresh data
        setSnackbarMessage('Heatmap linked successfully');
        setSnackbarType('success');
        setShowSnackbar(true);
      } catch (error) {
        console.error('Error linking heatmap:', error);
        console.log('Error details:', error.response?.data);
        setSnackbarMessage('Failed to link Heatmap');
        setSnackbarType('error');
        setShowSnackbar(true);
      }
    }
  };

  if (error) return <div>{error}</div>;
  if (!personas) return <div>Loading...</div>;

  function formatDateUpdated(date) {
    if (!date) return "No update date provided";

    const now = new Date();
    const updatedDate = parseISO(date);

    const diffInSeconds = (now.getTime() - updatedDate.getTime()) / 1000;

    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)} seconds ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    } else if (diffInSeconds < 604800) {
      return `${Math.floor(diffInSeconds / 86400)} days ago`;
    } else {
      return format(updatedDate, "dd MMM yyyy");
    }
  }

  const avatarUrl = personas && personas.avatar
    ? `https://panel.reislo.com/assets/${personas.avatar}?width=300&height=300&fit=cover&format=webp`
    : "https://thispersondoesnotexist.com/";

  return (
    <div className="pb-40 lg:pl-24">
      {showSnackbar && <Snackbar message={snackbarMessage} actionName={actionName} type={snackbarType} onClose={() => setShowSnackbar(false)} />}
      
      <div className="mx-auto max-w-75xl">
        <div className="flex gap-4 justify-between py-4 items-center mt-4">
          <div className="cursor-pointer" onClick={goBack}>
            <div className="bg-white border border-secondary-100 hover:shadow rounded-full p-3 flex items-center justify-center ">
              <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>
          <div className="flex gap-2 items-center cursor-pointer ">
            {loading ? (
              <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-secondary-500 hover:text-primary-500" role="status">
                <span className="visually-hidden"></span>
              </div>
            ) : (
              <CloudArrowUpIcon className="h-6 w-6 text-secondary-500 hover:text-primary-500" aria-hidden="true" />
            )} <span className="text-sm text-neutral-600">Saved {formatDateUpdated(personas.date_updated)}</span>
          </div>
          <div>
            <div className="cursor-pointer">
              <div className="bg-white border border-secondary-100 hover:shadow rounded-full p-3 flex items-center justify-center hidden">
                <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto pt-4 mt-0 bg-white rounded-lg">
        <div className="flex max-w-6xl mx-auto gap-8">
          <div className="flex-1">
            <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6">
              <div className="flex items-center gap-8">
                <div className="relative group">
                  <img
                    className="rounded-full w-32"
                    src={avatarUrl}
                    alt="Avatar"
                    onError={(e) => { e.target.onerror = null; e.target.src = "https://thispersondoesnotexist.com/"; }}
                  />
                  <div className="absolute inset-0 flex rounded-full cursor-pointer items-center justify-center bg-primary-500 bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity">
                    <ArrowPathIcon className="h-12 w-12 text-white" aria-hidden="true" />
                  </div>
                </div>
                <div className="flex flex-col text-left">
                  <h2 className="text-2xl">{personas.name}</h2>
                  <h4 className="text-lg opacity-60">{personas.age} years old</h4>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-y-2 gap-x-4 mt-8">
                <div className="pb-4">
                  <label className="text-sm opacity-60">Education</label>
                  <h4 className="text-base">{personas.education}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Occupation</label>
                  <h4 className="text-base">{personas.occupation}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Income</label>
                  <h4 className="text-base">${personas.income} yearly</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Family Life Cycle</label>
                  <h4 className="text-base">{personas.family_life_cycle}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Family Size</label>
                  <h4 className="text-base">{personas.family_size}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Living</label>
                  <h4 className="text-base">{personas.country}, {personas.city}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Type</label>
                  <h4 className="text-base">{personas.type}</h4>
                </div>
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6">
              <div className="editable-content">
                <div id="biography-editor"></div>
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6">
              <div className="editable-content">
                <div id="motivations-editor"></div>
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6">
              <div className="editable-content">
                <div id="needs-editor"></div>
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6">
              <div className="editable-content">
                <div id="frustrations-editor"></div>
              </div>
            </div>

            <div className="editable-content hidden mb-8 p-4 rounded-xl">
              <h3 className="text-2xl mb-2">MOSAIC Segmentation</h3>
            </div>
            <div className="editable-content mb-8 p-4 rounded-xl hidden">
              <h3 className="text-2xl mb-2">VALS Segmentation</h3>
            </div>
            <div className="editable-content mt-16 bg-grey-100 opacity-60 hidden">
              <p><strong>Prompt (Admin only):</strong> </p>
              <pre className="text-sm whitespace-pre-wrap">{JSON.stringify(JSON.parse(personas.prompt), null, 2)}</pre>
            </div>
          </div>

          <div className="w-80 space-y-6">
            <div className="bg-white border border-secondary-100 rounded-lg p-4">
              <h3 className="text-lg font-medium mb-3">Actions</h3>
              <div className="space-y-2">
                <button 
                  className="w-full text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 relative group"
                >
                  <FaceSmileIcon className="h-5 w-5" />
                  Create Empathy Map
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                    We're working on this feature
                  </div>
                </button>
                <button 
                  className="w-full text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 relative group"
                >
                  <PhotoIcon className="h-5 w-5" />
                  Create Moodboard
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                    We're working on this feature
                  </div>
                </button>
                <button 
                  className="w-full text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 relative group"
                >
                  <FolderArrowDownIcon className="h-5 w-5" />
                  Export Persona
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                    We're working on this feature
                  </div>
                </button>
                <button onClick={deletePersona} className="w-full text-left px-3 py-2 text-sm rounded-md hover:bg-red-50 text-red-600 flex items-center gap-2">
                  <TrashIcon className="h-5 w-5" />
                  Delete Persona
                </button>
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-4">
              <h3 className="text-lg font-medium mb-3">Project Assignment</h3>
              <div className="space-y-2">
                {personas.project?.project_name ? (
                  <div className="px-3 py-2 text-sm rounded-md hover:bg-secondary-50">
                    {personas.project.project_name}
                  </div>
                ) : (
                  <p className="text-sm text-secondary-500 px-3">Not assigned to any project</p>
                )}
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-4">
              <h3 className="text-lg font-medium mb-3">Linked Heatmaps</h3>
              <div className="space-y-2">
                {personas.linked_heatmaps?.length > 0 && 
                  personas.linked_heatmaps.map(heatmap => (
                    <div key={heatmap.heatmap_id?.id} className="px-3 py-2 text-sm rounded-md hover:bg-secondary-50">
                      {heatmap.heatmap_id?.name}
                    </div>
                  ))}
                <select
                  className="w-full text-left px-3 py-2 text-sm rounded-md border border-secondary-100"
                  onChange={handleHeatmapChange}
                  defaultValue=""
                >
                  <option value="">+ Link Heatmap</option>
                  {heatmaps.map(heatmap => (
                    <option key={heatmap.id} value={heatmap.id}>
                      {heatmap.name}
                    </option>
                  ))}
                  <option value="new">Create New Heatmap</option>
                </select>
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-4">
              <h3 className="text-lg font-medium mb-3">Linked User Journeys</h3>
              <div className="space-y-2">
                {personas.linked_user_journeys?.length > 0 && 
                  personas.linked_user_journeys.map(journey => (
                    <div key={journey.user_journey_id?.id} className="px-3 py-2 text-sm rounded-md hover:bg-secondary-50">
                      {journey.user_journey_id?.user_journey_name}
                    </div>
                  ))}
                <select
                  className="w-full text-left px-3 py-2 text-sm rounded-md border border-secondary-100"
                  onChange={handleUserJourneyChange}
                  defaultValue=""
                >
                  <option value="">+ Link User Journey</option>
                  {userJourneys.map(journey => (
                    <option key={journey.id} value={journey.id}>
                      {journey.user_journey_name}
                    </option>
                  ))}
                  <option value="new">Create New User Journey</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonasDetails;
