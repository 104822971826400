import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import reisloLogo from '../../img/logo-flat-color-1.svg';
import reisloSymbol from '../../img/symbol.svg'; // Make sure to import the symbol-only logo

const yourProjects = 'Recent Projects'

const Sidebar = ({ sidebarOpen, setSidebarOpen, navigation, classNames, teams, Cog6ToothIcon }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-900 px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-6 w-auto"
                      src={reisloLogo}
                      alt="Reislo logo"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                href={item.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-50 text-primary-500'
                                    : 'text-gray-700 hover:text-primary-500 hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current ? 'text-primary-500' : 'text-gray-400 group-hover:text-primary-500',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>


                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div 
        className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col transition-all duration-300 ease-in-out ${isHovered ? 'lg:w-64' : 'lg:w-20'}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex grow flex-col gap-y-8 overflow-y-auto border-r border-gray-200 bg-neutral-50 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center justify-left">
            <a href="/">
              <img
                className={`h-7 w-auto mt-2 transition-all duration-300 ease-in-out ${isHovered ? '' : 'h-5'}`}
                src={isHovered ? reisloLogo : reisloSymbol}
                alt="Reislo"
              />
            </a>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-3">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-neutral-500 bg-opacity-10 text-neutral-900'
                            : 'text-neutral-500 hover:text-neutral-900 hover:bg-neutral-400 hover:bg-opacity-10',
                          'group flex items-center rounded-md p-2 text-sm leading-6 transition-all duration-300 ease-in-out',
                          isHovered ? '' : 'justify-center'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current ? 'text-neutral-900' : 'text-neutral-600',
                            'h-5 w-5'
                          )}
                          aria-hidden="true"
                        />
                        <span className={`transition-all duration-300 ease-in-out ${isHovered ? 'opacity-100 pl-4' : 'opacity-0 w-0 pl-0'}`}>
                          {item.name}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;