import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const categories = ["Visual Design", "Layout", "Content", "Accessibility", "Usability"];
const priorities = ["High", "Medium", "Low"];
const statuses = ["Good", "Needs Improvement", "Critical"];

export default function DrawerSingleItem({ open, setOpen, item, onSave, onDelete, className, isEditMode }) {
  const [editedItem, setEditedItem] = useState({ ...item });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (open) {
      setShouldRender(true);
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setIsAnimating(true);
        });
      });
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    if (shouldRender) {
      const timer = setTimeout(() => {
        setIsAnimating(true);
      }, 30);
      return () => clearTimeout(timer);
    }
  }, [shouldRender]);

  useEffect(() => {
    if (item) {
      setEditedItem({ ...item });
    }
  }, [item]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedItem(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      await onSave?.(editedItem);
      setEditedItem(null);
      setOpen(false);
    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    onDelete?.(editedItem);
    setShowDeleteModal(false);
    setOpen(false);
  };

  const handleClose = () => {
    setEditedItem(null);
    setOpen(false);
  };

  const renderContent = () => {
    if (isEditMode) {
      return (
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={editedItem?.title || ''}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Category</label>
            <select
              name="insight_category"
              value={editedItem?.insight_category?.[0] || ''}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Status</label>
            <select
              name="status"
              value={editedItem?.status || ''}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            >
              <option value="">Select a status</option>
              {statuses.map((status) => (
                <option key={status} value={status}>{status}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Priority</label>
            <select
              name="priority"
              value={editedItem?.priority || ''}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            >
              <option value="">Select a priority</option>
              {priorities.map((priority) => (
                <option key={priority} value={priority.toLowerCase()}>{priority}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Score Value</label>
            <input
              type="number"
              name="score_value"
              min="0"
              max="100"
              value={editedItem?.score_value || ''}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Content</label>
            <textarea
              name="content"
              rows="3"
              value={editedItem?.content || ''}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Recommendation</label>
            <textarea
              name="recommendation"
              rows="3"
              value={editedItem?.recommendation || ''}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="space-y-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700">Title</h3>
            <p className="mt-1 text-sm text-gray-900">{item?.title || '-'}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700">Category</h3>
            <p className="mt-1 text-sm text-gray-900">{item?.insight_category?.[0] || '-'}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700">Status</h3>
            <p className="mt-1 text-sm text-gray-900">{item?.status || '-'}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700">Priority</h3>
            <p className="mt-1 text-sm text-gray-900">{item?.priority || '-'}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700">Score Value</h3>
            <p className="mt-1 text-sm text-gray-900">{item?.score_value || '-'}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700">Content</h3>
            <p className="mt-1 text-sm text-gray-900">{item?.content || '-'}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700">Recommendation</h3>
            <p className="mt-1 text-sm text-gray-900">{item?.recommendation || '-'}</p>
          </div>
        </div>
      );
    }
  };

  const renderFooter = () => {
    if (isEditMode) {
      return (
        <div className="flex justify-between">
          <button
            type="button"
            onClick={handleDelete}
            className="bg-red-50 py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-700 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Delete Item
          </button>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={handleClose}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Save
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleClose}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Close
          </button>
        </div>
      );
    }
  };

  if (!shouldRender) return null;

  return (
    <div
      className={`fixed inset-0 overflow-hidden ${
        open ? 'visible' : 'invisible'
      } transition-all duration-300 z-[60]`}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div 
        className={`absolute inset-0 bg-gray-500 transition-opacity duration-300 ease-in-out ${
          isAnimating ? 'opacity-75' : 'opacity-0'
        }`}
        onClick={() => setOpen(false)}
      />

      <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
        <div 
          className={`relative w-screen max-w-md transform transition-transform duration-300 ease-in-out ${
            isAnimating ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div className="px-4 py-6 sm:px-6">
              <div className="flex items-start justify-between">
                <h2 className="text-lg font-medium text-gray-900">
                  Edit Item Details
                </h2>
                <button
                  onClick={handleClose}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
            </div>

            <div className="flex-1 px-4 sm:px-6">
              {renderContent()}
            </div>

            <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
              {renderFooter()}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setShowDeleteModal(false)} />

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Item</h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete this item? This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={confirmDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 