import React, { useState, useEffect } from 'react';

function JourneyItemDrawer({ isOpen, onClose, onSave, itemType, phaseIndex, editData }) {
  const [itemName, setItemName] = useState('');
  const [itemContent, setItemContent] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (shouldRender) {
      // Trigger animation in next frame after render
      const timer = setTimeout(() => {
        setIsAnimating(true);
      }, 30);
      return () => clearTimeout(timer);
    }
  }, [shouldRender]);

  useEffect(() => {
    if (editData) {
      setItemName(editData.name || '');
      setItemContent(editData.content || '');
    } else {
      setItemName('');
      setItemContent('');
    }
  }, [editData]);

  const handleClose = () => {
    setIsAnimating(false);
    // Wait for animation to complete before calling onClose
    setTimeout(onClose, 300);
  };

  const handleSave = () => {
    onSave(phaseIndex, itemType, { name: itemName, content: itemContent });
    setItemName('');
    setItemContent('');
    handleClose();
  };

  if (!shouldRender) return null;

  return (
    <div className="fixed inset-0 overflow-hidden z-50">
      <div className="absolute inset-0 overflow-hidden">
        {/* Backdrop */}
        <div 
          className={`absolute inset-0 bg-gray-500 transition-opacity duration-300 ${
            isAnimating ? 'opacity-75' : 'opacity-0'
          }`} 
          onClick={handleClose}
        />
        
        {/* Drawer */}
        <div 
          className={`absolute inset-y-0 right-0 pl-10 max-w-full flex transform transition-transform duration-300 ease-in-out ${
            isAnimating ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="w-screen max-w-md">
            <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
              <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                <div className="flex items-start justify-between">
                  <h2 className="text-lg font-medium text-gray-900">Add New {itemType}</h2>
                  <button onClick={handleClose} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Close panel</span>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="mt-8">
                  <div className="flow-root">
                    <div className="space-y-6">
                      <div>
                        <label htmlFor="item-name" className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                          type="text"
                          id="item-name"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={itemName}
                          onChange={(e) => setItemName(e.target.value)}
                        />
                      </div>
                      <div>
                        <label htmlFor="item-content" className="block text-sm font-medium text-gray-700">Content</label>
                        <textarea
                          id="item-content"
                          rows="3"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={itemContent}
                          onChange={(e) => setItemContent(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JourneyItemDrawer;