import React from 'react';

function ContentHeader({ title, description, primaryAction, onPrimaryAction }) {
  return (
    <div className="sm:flex sm:items-center mt-16">
      <div className="sm:flex-auto">
        <h1 className="text-2xl font-medium leading-4 text-gray-900">{title}</h1>
        <p className="mt-4 text-sm text-gray-700  max-w-4xl">{description}</p>
      </div>
      {primaryAction && onPrimaryAction && (
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={onPrimaryAction}
            className="inline-flex items-center justify-center rounded-full bg-primary-500 px-6 py-3 text-base text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            {primaryAction}
          </button>
        </div>
      )}
    </div>
  );
}

export default ContentHeader;