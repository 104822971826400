import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_INSIGHTS_ENDPOINT } from '../../api';
import { ChevronLeftIcon, ChevronRightIcon, LightBulbIcon, PlusIcon } from '@heroicons/react/20/solid';
import ContentHeader from '../Atoms/contentHeader';
import { Bar } from 'react-chartjs-2';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const title = 'Insights';
const description = 'Navigate through the list to view specific information or to make updates to existing insights.';
const primaryAction = 'Create New Insight';

const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <LightBulbIcon className="mx-auto h-12 w-12 text-gray-400" />
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Create First Insight</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new insight. Transform your research into actionable insights.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white  hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New Insight
      </button>
    </div>
  </div>
);

const EmptyStateWithFilter = ({ onReset }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">No results found</h3>
    <p className="mt-1 text-sm text-gray-500">Try adjusting your search or filter to find what you're looking for.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onReset}
        className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white hover:bg-primary-600"
      >
        Reset filters
      </button>
    </div>
  </div>
);

const ValueBar = ({ value }) => {
  const normalizedValue = Math.min(Math.max(value, 0), 100);
  
  // Helper function to determine the color based on value
  const getBackgroundColor = (value) => {
    if (value <= 20) return '#ef4444'; // red
    if (value <= 50) return '#f87171'; // lighter red
    if (value <= 70) return '#fbbf24'; // yellow
    if (value <= 90) return '#34d399'; // green
    return '#22c55e'; // very green
  };

  return (
    <div className="flex items-center gap-2 w-[254px]">
      <div className="relative flex-grow h-[6px] bg-gray-100 rounded overflow-hidden">
        <div 
          className="absolute h-full rounded transition-all duration-500"
          style={{ 
            width: `${normalizedValue}%`,
            backgroundColor: getBackgroundColor(normalizedValue)
          }}
        />
      </div>
      <span className="text-xs text-gray-600 whitespace-nowrap">
        {normalizedValue}%
      </span>
    </div>
  );
};

function InsightsTable() {
  const [insights, setInsights] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [allInsights, setAllInsights] = useState([]);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const fetchData = async (start = dateRange[0].startDate, end = dateRange[0].endDate) => {
    setLoading(true);
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to start of day
      const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const nextMonthEnd = new Date(today.getFullYear(), today.getMonth() + 2, 0);

      const allDataResponse = await axios.get(DIRECTUS_INSIGHTS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: {
            _and: [
              { user_created: { _eq: userId } },
              { date_created: { _gte: lastMonthStart.toISOString() } },
              { date_created: { _lte: today.toISOString() } }
            ]
          },
          limit: -1,
          sort: '-date_created',
          fields: ['date_created']
        }
      });

      const dates = [];
      const currentDate = new Date(lastMonthStart);
      
      while (currentDate <= nextMonthEnd) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const dailyCount = {};
      dates.forEach(date => {
        const dateKey = date.toISOString().split('T')[0];
        dailyCount[dateKey] = 0;
      });

      allDataResponse.data.data.forEach(insight => {
        const dateKey = new Date(insight.date_created).toISOString().split('T')[0];
        if (dailyCount[dateKey] !== undefined) {
          dailyCount[dateKey] += 1;
        }
      });

      // Calculate average from current month data up to today
      const currentMonthDays = Object.keys(dailyCount).filter(date => 
        date >= currentMonthStart.toISOString().split('T')[0] &&
        date <= today.toISOString().split('T')[0]
      );

      const currentMonthTotal = currentMonthDays.reduce((sum, date) => sum + dailyCount[date], 0);
      let averagePerDay = currentMonthTotal / currentMonthDays.length;
      
      // Calculate growing prediction starting from today
      const growthRate = 1.05; // 5% growth rate per day
      let currentAverage = averagePerDay;
      
      // Reset future dates
      const futureDates = Object.keys(dailyCount).filter(date => 
        new Date(date) > today
      );
      
      futureDates.forEach((date, index) => {
        currentAverage *= growthRate;
        dailyCount[date] = Math.round(currentAverage * 10) / 10; // Round to 1 decimal
      });

      const sortedDates = Object.keys(dailyCount).sort();

      setChartData({
        labels: sortedDates.map(dateStr => {
          const date = new Date(dateStr);
          return date.toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric' 
          });
        }),
        datasets: [
          {
            label: 'Last Month',
            data: sortedDates.map(date => {
              if (date >= lastMonthStart.toISOString().split('T')[0] && 
                  date < currentMonthStart.toISOString().split('T')[0]) {
                return dailyCount[date];
              }
              return null;
            }),
            backgroundColor: 'rgba(156, 163, 175, 0.6)',
            borderColor: 'rgba(156, 163, 175, 1)',
            borderWidth: 1,
            stack: 'stack',
            type: 'bar'
          },
          {
            label: 'Current Month',
            data: sortedDates.map(date => {
              if (date >= currentMonthStart.toISOString().split('T')[0] && 
                  date <= today.toISOString().split('T')[0]) {
                return dailyCount[date];
              }
              return null;
            }),
            backgroundColor: 'rgba(59, 130, 246, 0.6)',
            borderWidth: 0,
            stack: 'stack',
            type: 'bar'
          },
          {
            label: 'Prediction',
            data: sortedDates.map(date => {
              if (new Date(date) > today) {
                return dailyCount[date];
              }
              return null;
            }),
            backgroundColor: 'transparent',
            borderColor: 'rgba(251, 191, 36, 1)', // yellow
            borderWidth: 2,
            borderDash: [5, 5],
            tension: 0.4,
            type: 'line',
            stack: undefined,
            fill: false,
            pointRadius: 2,
            pointBackgroundColor: 'rgba(251, 191, 36, 1)',
            pointBorderColor: 'rgba(251, 191, 36, 1)',
            pointBorderWidth: 1,
            pointHoverRadius: 4,
          }
        ]
      });

      const paginatedResponse = await axios.get(DIRECTUS_INSIGHTS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: {
            _and: [
              { user_created: { _eq: userId } },
              { date_created: { _gte: start.toISOString() } },
              { date_created: { _lte: end.toISOString() } }
            ]
          },
          fields: [
            '*',
            'project.project_name',
            'userjourney.user_journey_name',
            'userjourney.id',
            'userjourney.phase.phase_name',
            'userjourney.phase.painpoints.insight_id',
            'userjourney.phase.gains.insight_id',
            'user_created'
          ],
          meta: 'filter_count',
          limit: itemsPerPage,
          page: currentPage
        }
      });

      setInsights(paginatedResponse.data.data);
      const totalCount = paginatedResponse.data.meta.filter_count;
      setTotalPages(Math.ceil(totalCount / itemsPerPage));

      setLoading(false);
    } catch (error) {
      console.error('Error fetching insights:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, token, userId, itemsPerPage]);

  useEffect(() => {
    if (dateRange[0].startDate && dateRange[0].endDate) {
      fetchData(dateRange[0].startDate, dateRange[0].endDate);
    }
  }, [dateRange]);

  const resetFilters = () => {
    setDateRange([{
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
      endDate: new Date(),
      key: 'selection'
    }]);
    setCurrentPage(1);
    fetchData();
  };

  const SkeletonRow = () => (
    <tr>
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm">
        <div className="h-5 w-5 bg-gray-200 rounded animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-40 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-16 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
      </td>
    </tr>
  );

  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const getInsightTypeStyle = (type) => {
    const styles = {
      pain: 'text-red-700 bg-red-50 ring-red-600/20',
      gain: 'text-green-700 bg-green-50 ring-green-600/20',
    };
    return styles[type] || 'text-gray-700 bg-gray-50 ring-gray-600/20';
  };

  const getPriorityStyle = (priority) => {
    const styles = {
      high: 'text-red-700 bg-red-50',
      medium: 'text-yellow-700 bg-yellow-50',
      low: 'text-green-700 bg-green-50',
    };
    return styles[priority] || 'text-gray-700 bg-gray-50';
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (newSize) => {
    setItemsPerPage(Number(newSize));
    setCurrentPage(1);
  };

  return (
    <div className="lg:pl-24">
      <div className="mx-auto">
        {insights.length > 0 && !loading && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={() => navigate('/insights/create')}
          />
        )}

        {!loading && insights.length > 0 && (
          <div className="mb-8">
            <div className="relative mb-4">
              <button
                type="button"
                onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {dateRange[0].startDate.toLocaleDateString()} - {dateRange[0].endDate.toLocaleDateString()}
                <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
              </button>

              {isDatePickerVisible && (
                <div className="absolute z-10 mt-2">
                  <div className="relative">
                    <DateRangePicker
                      onChange={item => {
                        setDateRange([item.selection]);
                        setIsDatePickerVisible(false);
                        fetchData(item.selection.startDate, item.selection.endDate);
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={dateRange}
                      direction="horizontal"
                      className="border border-gray-200 rounded-lg bg-white shadow-lg"
                    />
                    <div className="absolute top-0 right-0 p-2">
                      <button
                        onClick={() => setIsDatePickerVisible(false)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Close</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            <div className="h-80 bg-white">
              <Bar
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1
                      },
                      stacked: true
                    },
                    x: {
                      grid: {
                        display: false
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: 'top',
                      labels: {
                        usePointStyle: true,
                        padding: 20
                      }
                    },
                    title: {
                      display: true,
                      text: 'Insights Added Per Day'
                    },
                    tooltip: {
                      callbacks: {
                        title: (context) => {
                          return new Date(context[0].label).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          });
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        )}

        <div className="mt-8 flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Array.from({ length: itemsPerPage }).map((_, index) => (
                      <SkeletonRow key={index} />
                    ))}
                  </tbody>
                </table>
              ) : insights.length === 0 ? (
                dateRange[0].startDate.getTime() === new Date(new Date().setMonth(new Date().getMonth() - 3)).getTime() ? (
                  <EmptyState onPrimaryClick={() => navigate('/insights/create')} />
                ) : (
                  <EmptyStateWithFilter onReset={resetFilters} />
                )
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 max-w-4">Type</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Priority</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Business Value</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Project</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">User Journey</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Author</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {insights.map((insight) => (
                      <tr
                        key={insight.id}
                        onClick={() => navigate(`/insights/${insight.id}`)}
                        className="cursor-pointer hover:bg-gray-50"
                      >
                        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm max-w-4">
                          {insight.insight_type === 'pain' ? (
                            <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" />
                            </svg>
                          ) : insight.insight_type === 'success' ? (
                            <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" />
                            </svg>
                          ) : insight.insight_type === 'critical' ? (
                            <svg className="h-5 w-5 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                            </svg>
                          ) : (
                            <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" />
                            </svg>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3  text-sm text-gray-900">
                          {insight.description.length > 25 
                            ? `${insight.description.substring(0, 25)}...` 
                            : insight.description}
                        </td>
                        <td className="whitespace-nowrap px-3 text-sm">
                          <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getPriorityStyle(insight.piority)}`}>
                            {insight.piority}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 text-sm">
                          <ValueBar value={Math.floor(Math.random() * 100)} />
                        </td>
                        <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                          {insight.project?.project_name || 'No Project'}
                        </td>
                        <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                          {insight.userjourney ? (
                            <div className="flex flex-col gap-1">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/user-journeys/${insight.userjourney.id}`);
                                }}
                                className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium bg-blue-50 text-blue-700 hover:bg-blue-100 transition-colors"
                              >
                                {insight.userjourney.user_journey_name}
                              </button>
                              {insight.userjourney.phase?.map((phase, index) => {
                                const isInPainpoints = phase.painpoints?.some(p => p.insight_id === insight.id);
                                const isInGains = phase.gains?.some(g => g.insight_id === insight.id);
                                if (isInPainpoints || isInGains) {
                                  return (
                                    <span key={index} className="text-xs text-gray-500 pl-2">
                                      • {phase.phase_name} ({isInPainpoints ? 'Pain' : 'Gain'})
                                    </span>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          ) : (
                            <span className="text-gray-400">No Journey</span>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3  text-sm">
                          <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                            <span className="text-xs text-gray-600">
                              {insight.user_created?.charAt(0).toUpperCase() || 'U'}
                            </span>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                          {formatDate(insight.date_created)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        {!loading && insights.length > 0 && (
          <nav className="border-t border-gray-200 px-8 py-3 flex items-center justify-between sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative ml-3 inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div className="flex items-center gap-4">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{' '}
                  <span className="font-medium">{Math.min(currentPage * itemsPerPage, insights.length)}</span> of{' '}
                  <span className="font-medium">{totalPages * itemsPerPage}</span> results
                </p>
                <select
                  value={itemsPerPage}
                  onChange={(e) => handlePageSizeChange(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                >
                  <option value="25">25 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="500">500 per page</option>
                </select>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md -space-x-px" aria-label="Pagination">
                  <button 
                    onClick={() => handlePageChange(currentPage - 1)} 
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </button>
                  {totalPages <= 5 ? (
                    Array.from({ length: totalPages }, (_, i) => (
                      <button
                        key={i}
                        aria-current={currentPage === i + 1 ? 'page' : undefined}
                        onClick={() => handlePageChange(i + 1)}
                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                          currentPage === i + 1 
                            ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' 
                            : 'text-gray-500 hover:bg-gray-50'
                        }`}
                      >
                        {i + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {[1, 2].map((pageNum) => (
                        <button
                          key={pageNum}
                          aria-current={currentPage === pageNum ? 'page' : undefined}
                          onClick={() => handlePageChange(pageNum)}
                          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                            currentPage === pageNum 
                              ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' 
                              : 'text-gray-500 hover:bg-gray-50'
                          }`}
                        >
                          {pageNum}
                        </button>
                      ))}
                      <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                        ...
                      </span>
                      {[totalPages - 1, totalPages].map((pageNum) => (
                        <button
                          key={pageNum}
                          aria-current={currentPage === pageNum ? 'page' : undefined}
                          onClick={() => handlePageChange(pageNum)}
                          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                            currentPage === pageNum 
                              ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' 
                              : 'text-gray-500 hover:bg-gray-50'
                          }`}
                        >
                          {pageNum}
                        </button>
                      ))}
                    </>
                  )}
                  <button 
                    onClick={() => handlePageChange(currentPage + 1)} 
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </button>
                </nav>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default InsightsTable;
