import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { DIRECTUS_INSTANCE } from '../../api';
import axios from 'axios';
import gradientBg from '../../img/gradient.png';
import { DocumentPlusIcon, UsersIcon, MapIcon, ChartBarIcon } from '@heroicons/react/24/outline';

const Dashboard = () => {
  const navigate = useNavigate();
  const [name, setName] = useState(() => localStorage.getItem('username') || '');
  
  // Fetch user profile data
  const { data: userProfile } = useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const response = await axios.get(`${DIRECTUS_INSTANCE}/users/me`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('directus_token')}`,
        },
      });
      return response.data.data;
    },
    onSuccess: (data) => {
      setName(data.first_name);
      localStorage.setItem('username', data.first_name);
    }
  });

  const quickActions = [
    { 
      title: 'Create New Project Canvas', 
      desc: 'Start a new research project',
      path: '/projects/create-project',
      icon: DocumentPlusIcon
    },
    { 
      title: 'Create Personas', 
      desc: 'Define your target users',
      path: '/personas/create-persona',
      icon: UsersIcon
    },
    { 
      title: 'User Journey Map', 
      desc: 'Map out user experiences',
      path: '/user-journeys/create',
      icon: MapIcon
    },
    { 
      title: 'UX Analysis', 
      desc: 'Analyze user behavior',
      path: '/predictions/create-heatmap',
      icon: ChartBarIcon
    }
  ];

  const dailyQuotes = [
    "Design is not just what it looks like and feels like. Design is how it works. - Steve Jobs",
    "If I had asked people what they wanted, they would have said faster horses. - Henry Ford",
    "Make it simple, but significant. - Don Draper",
    "Good design is obvious. Great design is transparent. - Joe Sparano",
    "Technology is nothing. What's important is that you have faith in people. - Steve Jobs",
    "The details are not the details. They make the design. - Charles Eames",
    "Design is intelligence made visible. - Alina Wheeler",
    "Innovation distinguishes between a leader and a follower. - Steve Jobs",
    "Simplicity is the ultimate sophistication. - Leonardo da Vinci",
    "Design is not for philosophy, it's for life. - Issey Miyake",
    "The best way to predict the future is to create it. - Peter Drucker",
    "Good design is good business. - Thomas Watson Jr., IBM",
    "Everything is designed. Few things are designed well. - Brian Reed",
    "Design creates culture. Culture shapes values. Values determine the future. - Robert L. Peters",
    "The user experience design of a product essentially lies between art and science. - Alok Nath",
    "A user interface is like a joke. If you have to explain it, it's not that good. - Martin LeBlanc",
    "Design is where science and art break even. - Robin Mathew",
    "The best ideas come as jokes. Make your thinking as funny as possible. - David Ogilvy",
    "Innovation is saying no to 1,000 things. - Steve Jobs",
    "Design is thinking made visual. - Saul Bass"
  ];

  const getTodayQuote = () => {
    const day = new Date().getDate();
    return dailyQuotes[day % dailyQuotes.length];
  };

  const recentItems = [
    { 
      title: 'Heatmap Analysis', 
      desc: 'User interaction patterns',
      path: '/predictions'
    },
    { 
      title: 'User Personas', 
      desc: 'Target audience profiles',
      path: '/personas'
    },
    { 
      title: 'Customer Journey', 
      desc: 'End-to-end user experience',
      path: '/user-journeys'
    },
    { 
      title: 'Feature Usage', 
      desc: 'Product analytics report',
      path: '/insights'
    }
  ];

  return (
    <div className="lg:pl-16 pt-0 bg-white">
      <div className="mx-auto flex flex-col gap-0.5">
        {/* Welcome Section */}
        <div className="p-11" style={{ backgroundImage: `url(${gradientBg})` }}>
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl text-gray-900 leading-9">Welcome {userProfile?.first_name || name}</h1>
            <p className="text-base text-gray-900">{getTodayQuote()}</p>
          </div>
        </div>

        {/* Quick Actions Section */}
        <div className="p-8 bg-white">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col">
              <h2 className="text-xl text-gray-900 leading-loose">What would you like do today?</h2>
              <p className="text-sm text-gray-700">Design a study tailored to your research goals</p>
            </div>
            
            <div className="flex gap-4">
              {quickActions.map((item) => (
                <div 
                  key={item.title} 
                  className="flex-1 p-4 bg-white rounded-lg border border-neutral-200 cursor-pointer hover:border-primary-500 transition-colors"
                  onClick={() => navigate(item.path)}
                >
                  <div className="flex items-center gap-4">
                    <div className="w-10 h-10 flex items-center justify-center">
                      <item.icon className="w-6 h-6 text-gray-600" />
                    </div>
                    <div className="flex flex-col gap-px">
                      <div className="text-sm text-gray-900">{item.title}</div>
                      <div className="text-xs text-gray-600">{item.desc}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Latest Created Section */}
        <div className="p-8">
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-xl text-gray-900 leading-loose">Latest Created</h2>
                <p className="text-sm text-gray-900">Your most recent research activities</p>
              </div>
              <div 
                className="flex flex-col cursor-pointer"
                onClick={() => navigate('/projects')}
              >
                <span className="text-xs text-primary-500">View all</span>
                <div className="h-0.5 bg-primary-500"></div>
              </div>
            </div>

            <div className="flex gap-3.5">
              {recentItems.map((item) => (
                <div 
                  key={item.title} 
                  className="flex-1 p-6 rounded-xl border border-neutral-200 cursor-pointer hover:border-primary-500 transition-colors"
                  onClick={() => navigate(item.path)}
                >
                  <div className="flex flex-col gap-3">
                    <div className="bg-gray-300 p-5"></div>
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-2">
                        <h3 className="text-base text-gray-900">{item.title}</h3>
                        <p className="text-xs text-gray-900">{item.desc}</p>
                      </div>
                      <span className="text-sm text-gray-900">Last edited 2d ago</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
