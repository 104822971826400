import React, { useEffect, useRef, useState } from 'react';
import h337 from 'heatmapjs';

import PropTypes from 'prop-types';
import { generateRealisticEyeTrackingData } from './Eyetracking/eyeTrackingSimulation';
import { AIVisionSimulator } from './Eyetracking/aiVisionSimulation';

class AIVisionAnalyzer {
  async analyzeImage(imageUrl, options) {
    // To jest przykładowa implementacja - powinna być zastąpiona rzeczywistym API
    return {
      faces: [
        { centerX: 100, centerY: 100 }
      ],
      textAreas: [
        { centerX: 200, centerY: 200 }
      ],
      saliencyPoints: [
        { x: 300, y: 300, value: 0.8 }
      ]
    };
  }
}

const HeatmapOverlay = ({ imageUrl, data, isVisible, mode = 'standard', showHeatmap = true, onShowHeatmapChange }) => {
  const containerRef = useRef(null);
  const heatmapRef = useRef(null);
  const [dimensions, setDimensions] = useState(null);
  const [containerSize, setContainerSize] = useState(null);
  const [focalPoint, setFocalPoint] = useState({ x: 0, y: 0 });
  const [viewMode, setViewMode] = useState('eyetracking'); // 'standard' | 'eyetracking' | 'heatmap'
  const simulator = useRef(null);

  // Konfiguracja symulacji widzenia
  const visionConfig = {
    foveal: { radius: 50, intensity: 1.0 },    // Ostre widzenie centralne
    parafoveal: { radius: 150, intensity: 0.6 }, // Widzenie okołośrodkowe
    peripheral: { radius: 300, intensity: 0.2 }  // Widzenie peryferyjne
  };

  // Dodaj nowe stałe na początku komponentu
  const FIXATION_TIME = 300; // Czas fiksacji w ms
  const SACCADE_SPEED = 0.3; // Prędkość ruchu oczu w px/ms
  const ATTENTION_THRESHOLD = 0.7; // Próg uwagi dla ważnych obszarów

  // Dodaj nowy stan
  const [currentSimulation, setCurrentSimulation] = useState(null);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight
      });
    };
    img.src = imageUrl;
  }, [imageUrl]);

  useEffect(() => {
    const updateContainerSize = () => {
      if (containerRef.current && dimensions) {
        const container = containerRef.current.parentElement;
        const containerWidth = container.offsetWidth;
        const scale = containerWidth / dimensions.width;
        
        setContainerSize({
          width: containerWidth,
          height: dimensions.height * scale
        });
      }
    };

    updateContainerSize();
    window.addEventListener('resize', updateContainerSize);
    return () => window.removeEventListener('resize', updateContainerSize);
  }, [dimensions]);

  const generateVisionData = (width, height, focal) => {
    const points = [];
    const density = 10; // Gęstość punktów w heatmapie

    for (let x = 0; x < width; x += density) {
      for (let y = 0; y < height; y += density) {
        const distance = Math.sqrt(
          Math.pow(x - focal.x, 2) + 
          Math.pow(y - focal.y, 2)
        );

        let intensity;
        if (distance < visionConfig.foveal.radius) {
          intensity = visionConfig.foveal.intensity;
        } else if (distance < visionConfig.parafoveal.radius) {
          intensity = visionConfig.parafoveal.intensity;
        } else if (distance < visionConfig.peripheral.radius) {
          intensity = visionConfig.peripheral.intensity;
        } else {
          intensity = 0.1; // Minimalna widoczność poza obszarem peryferyjnym
        }

        points.push({
          x: x,
          y: y,
          value: intensity * 100
        });
      }
    }

    return points;
  };

  useEffect(() => {
    if (mode === 'eyetracking' && !simulator.current) {
      simulator.current = new AIVisionSimulator();
    }
  }, [mode]);

  const generateAIEyeTrackingData = async (width, height) => {
    if (!simulator.current) return [];

    try {
      const simulationData = await simulator.current.simulateViewingSession(
        imageUrl,
        15000 // 15 sekund symulacji
      );

      return simulationData;
    } catch (error) {
      console.error('AI Vision simulation failed:', error);
      // Fallback do podstawowej symulacji
      return generateRealisticEyeTrackingData(width, height, 'article');
    }
  };

  useEffect(() => {
    if (!containerRef.current || !containerSize || !isVisible) return;

    if (containerRef.current) {
      containerRef.current.innerHTML = '';
    }

    // Konfiguracja heatmapy dostosowana do symulacji widzenia
    heatmapRef.current = h337.create({
      container: containerRef.current,
      radius: mode === 'vision' ? 30 : 50,
      maxOpacity: mode === 'vision' ? 0.85 : 0.6,
      minOpacity: 0,
      blur: mode === 'vision' ? 0.90 : 0.75,
      gradient: mode === 'vision' ? {
        '.2': 'rgba(0,0,0,0.6)',
        '.4': 'rgba(0,0,0,0.4)',
        '.6': 'rgba(0,0,0,0.2)',
        '.8': 'rgba(0,0,0,0.1)',
        '1': 'rgba(0,0,0,0)'
      } : {
        '.5': 'blue',
        '.8': 'red',
        '.95': 'white'
      },
      width: containerSize.width,
      height: containerSize.height
    });

    let pointsData;
    if (mode === 'vision') {
      pointsData = generateVisionData(
        containerSize.width,
        containerSize.height,
        {
          x: containerSize.width / 2,
          y: containerSize.height / 2
        }
      );
    } else if (mode === 'eyetracking') {
      const eyeTrackingPoints = generateEyeTrackingHeatmap(
        containerSize.width,
        containerSize.height,
        'article'
      );

      heatmapRef.current.setData({
        max: Math.max(...eyeTrackingPoints.map(p => p.value)),
        data: eyeTrackingPoints
      });
    } else {
      pointsData = data ? data.map(point => ({
        ...point,
        x: (point.x / dimensions.width) * containerSize.width,
        y: (point.y / dimensions.height) * containerSize.height,
      })) : generateSampleData(containerSize.width, containerSize.height);
    }

    heatmapRef.current.setData({
      max: 100,
      data: pointsData
    });

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
      heatmapRef.current = null;
    };
  }, [containerSize, isVisible, data, dimensions, mode, focalPoint]);

  const handleClick = (e) => {
    if (mode !== 'vision') return;

    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setFocalPoint({ x, y });
  };

  const generateSampleData = (width, height) => {
    const points = [];
    for (let i = 0; i < 200; i++) {
      points.push({
        x: Math.floor(Math.random() * width),
        y: Math.floor(Math.random() * height),
        value: Math.floor(Math.random() * 100)
      });
    }
    return points;
  };

  const generateEyeTrackingHeatmap = (width, height, contentType) => {
    return generateRealisticEyeTrackingData(
      width,
      height,
      contentType,
      15000 // 15 sekund symulacji
    );
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    if (onShowHeatmapChange) {
      onShowHeatmapChange(true);
    }
  };

  // Nowa funkcja do znajdowania obszarów o wysokiej atencji
  const findHighAttentionAreas = async (imageUrl) => {
    const areas = [];
    
    try {
      // Użyj API wizyjnego (np. TensorFlow.js lub zewnętrznego API) do analizy obrazu
      const visionAPI = new AIVisionAnalyzer(); // Hipotetyczna klasa
      const analysis = await visionAPI.analyzeImage(imageUrl, {
        detectFaces: true,
        detectText: true,
        detectObjects: true,
        saliencyMap: true
      });

      // Przetwórz wyniki analizy na punkty uwagi
      areas.push(...analysis.faces.map(face => ({
        x: face.centerX,
        y: face.centerY,
        weight: 1.0,
        type: 'face'
      })));

      areas.push(...analysis.textAreas.map(text => ({
        x: text.centerX,
        y: text.centerY,
        weight: 0.8,
        type: 'text'
      })));

      // Dodaj punkty z mapy istotności (saliency map)
      areas.push(...analysis.saliencyPoints
        .filter(point => point.value > ATTENTION_THRESHOLD)
        .map(point => ({
          x: point.x,
          y: point.y,
          weight: point.value,
          type: 'saliency'
        })));

    } catch (error) {
      console.error('Vision analysis failed:', error);
      // Fallback do prostszej analizy obrazu
      areas.push(...generateBasicAttentionPoints(imageUrl));
    }

    return areas;
  };

  // Nowa funkcja do symulacji ruchu oczu
  const simulateEyeMovement = async () => {
    if (!containerSize) return;

    const attentionAreas = await findHighAttentionAreas(imageUrl);
    let currentPoint = { x: containerSize.width / 2, y: containerSize.height / 2 };
    
    const simulate = async () => {
      // Sortuj obszary według ważności i odległości od obecnej pozycji
      const nextAreas = attentionAreas
        .sort((a, b) => {
          const distA = Math.hypot(a.x - currentPoint.x, a.y - currentPoint.y);
          const distB = Math.hypot(b.x - currentPoint.x, b.y - currentPoint.y);
          return (b.weight / distB) - (a.weight / distA);
        });

      for (const area of nextAreas) {
        // Symuluj ruch oczu (saccade)
        const distance = Math.hypot(area.x - currentPoint.x, area.y - currentPoint.y);
        const movementTime = distance / SACCADE_SPEED;
        
        await animateEyeMovement(currentPoint, area, movementTime);
        
        // Symuluj fiksację
        await new Promise(resolve => setTimeout(resolve, FIXATION_TIME));
        
        // Aktualizuj heatmapę
        updateHeatmapData({
          x: area.x,
          y: area.y,
          value: area.weight * 100
        });

        currentPoint = { x: area.x, y: area.y };
      }
    };

    setCurrentSimulation(simulate());
  };

  // Funkcja do płynnej animacji ruchu oczu
  const animateEyeMovement = (start, end, duration) => {
    return new Promise(resolve => {
      const startTime = performance.now();
      
      const animate = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        
        // Funkcja easingu dla bardziej naturalnego ruchu
        const eased = easeInOutCubic(progress);
        
        const currentX = start.x + (end.x - start.x) * eased;
        const currentY = start.y + (end.y - start.y) * eased;
        
        setFocalPoint({ x: currentX, y: currentY });
        
        if (progress < 1) {
          animationFrameRef.current = requestAnimationFrame(animate);
        } else {
          resolve();
        }
      };
      
      animationFrameRef.current = requestAnimationFrame(animate);
    });
  };

  // Funkcja easingu
  const easeInOutCubic = (t) => {
    return t < 0.5
      ? 4 * t * t * t
      : 1 - Math.pow(-2 * t + 2, 3) / 2;
  };

  // Dodaj cleanup do useEffect
  useEffect(() => {
    if (mode === 'eyetracking') {
      simulateEyeMovement();
    }
    
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (currentSimulation) {
        // Zatrzymaj obecną symulację
        setCurrentSimulation(null);
      }
    };
  }, [mode, containerSize]);

  const generateBasicAttentionPoints = (imageUrl) => {
    // Prosta implementacja fallbackowa
    return [
      { x: 100, y: 100, weight: 0.8, type: 'default' },
      { x: 200, y: 200, weight: 0.6, type: 'default' },
      { x: 300, y: 300, weight: 0.7, type: 'default' }
    ];
  };

  const updateHeatmapData = (point) => {
    if (!heatmapRef.current) return;

    const currentData = heatmapRef.current.getData();
    const newData = {
      max: currentData.max,
      data: [...currentData.data, point]
    };

    heatmapRef.current.setData(newData);
  };

  return (
    <div 
      style={{ 
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: mode === 'vision' ? 'auto' : 'none',
        zIndex: 10
      }}
      onClick={handleClick}
    >
      <div 
        ref={containerRef}
        style={{ 
          width: containerSize?.width || '100%',
          height: containerSize?.height || '100%',
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 0.3s ease',
          mixBlendMode: mode === 'vision' ? 'multiply' : 'multiply',
          position: 'absolute',
          top: 0,
          left: 0
        }}
      />
    </div>
  );
};

HeatmapOverlay.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  data: PropTypes.array,
  isVisible: PropTypes.bool,
  mode: PropTypes.oneOf(['standard', 'eyetracking', 'vision']),
  showHeatmap: PropTypes.bool,
  onShowHeatmapChange: PropTypes.func
};

export default HeatmapOverlay;