import React, { useEffect, useState } from 'react';
import ProfileDropdown from './Dropdown';
import TokensStatus from './Tokens';
import Notification from './notification';
import Breadcrumbs from '../Breadcrumbs/breadcrumbsComponent';
import axios from 'axios';

const Header = ({ setSidebarOpen, userNavigation, classNames, Bars3Icon }) => {
  const [username, setUsername] = useState('');
  const [lastName, setLastName] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('directus_token');
      if (!token) return;

      try {
        const response = await axios.get('https://panel.reislo.com/users/me', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['first_name', 'last_name', 'linked_workspace.workspace_name']
          }
        });

        const userData = response.data.data;
        setUsername(userData.first_name || '');
        setLastName(userData.last_name || '');
        setWorkspaceName(userData.linked_workspace?.workspace_name || '');

        localStorage.setItem('username', userData.first_name || '');
        localStorage.setItem('lastname', userData.last_name || '');
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="top-0 z-40 lg:mx-auto lg:pl-8 bg-white">
      <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none lg:pr-8">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <Breadcrumbs />
        <div className="flex flex-1 justify-end gap-x-4 lg:gap-x-6">
          <div className="flex items-center">
            <span className="text-sm font-medium text-gray-900 mr-4">{workspaceName}</span>
          </div>
          <TokensStatus />
          <Notification />
          <ProfileDropdown
            userNavigation={userNavigation}
            classNames={classNames}
            username={username}
            lastName={lastName}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;