import React from 'react';
import PropTypes from 'prop-types';

const Stepper = ({ steps, onStepClick }) => (
  <nav aria-label="Progress" className="max-w-5xl mx-auto">
    <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
      {steps.map((step, index) => (
        <li key={step.title} className="md:flex-1">
          <div
            onClick={() => onStepClick(index + 1)}
            className={`group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 cursor-pointer
              ${step.status === 'complete' 
                ? 'border-primary-500 hover:border-primary-600' 
                : step.status === 'current'
                ? 'border-primary-500'
                : 'border-gray-200 hover:border-gray-300'}`}
          >
            <span 
              className={`text-sm font-medium ${
                step.status === 'complete' || step.status === 'current'
                  ? 'text-primary-500'
                  : 'text-gray-500'
              }`}
            >
              {step.id}
            </span>
            <span className="text-sm font-medium">{step.title}</span>
          </div>
        </li>
      ))}
    </ol>
  </nav>
);

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      status: PropTypes.oneOf(['complete', 'current', 'upcoming']).isRequired,
    })
  ).isRequired,
  onStepClick: PropTypes.func.isRequired,
};

export default Stepper;
