export const commonPatterns = [
  {
    name: 'F-Pattern',
    weight: 0.8,
    data: [
      // Górny pasek
      ...Array.from({length: 10}, (_, i) => ({
        x: i * 10,
        y: 10,
        duration: 200,
        timestamp: i * 200
      })),
      // Lewa kolumna
      ...Array.from({length: 8}, (_, i) => ({
        x: 10,
        y: i * 10 + 20,
        duration: 150,
        timestamp: 2000 + i * 150
      })),
      // Środkowy skan
      ...Array.from({length: 6}, (_, i) => ({
        x: i * 10,
        y: 40,
        duration: 100,
        timestamp: 3200 + i * 100
      }))
    ]
  },
  {
    name: 'Z-Pattern',
    weight: 0.7,
    data: [
      // Górna linia
      ...Array.from({length: 10}, (_, i) => ({
        x: i * 10,
        y: 10,
        duration: 200,
        timestamp: i * 200
      })),
      // Ukośna linia
      ...Array.from({length: 8}, (_, i) => ({
        x: (i + 1) * 12,
        y: (i + 1) * 12,
        duration: 150,
        timestamp: 2000 + i * 150
      })),
      // Dolna linia
      ...Array.from({length: 10}, (_, i) => ({
        x: i * 10,
        y: 90,
        duration: 200,
        timestamp: 3200 + i * 200
      }))
    ]
  },
  {
    name: 'Gutenberg-Pattern',
    weight: 0.6,
    data: [
      // Główne obszary zainteresowania w układzie Gutenberga
      // Lewy górny róg
      ...Array.from({length: 4}, (_, i) => ({
        x: 10 + i * 5,
        y: 10 + i * 5,
        duration: 300,
        timestamp: i * 300
      })),
      // Prawy górny róg
      ...Array.from({length: 4}, (_, i) => ({
        x: 80 - i * 5,
        y: 10 + i * 5,
        duration: 200,
        timestamp: 1200 + i * 200
      })),
      // Środek
      ...Array.from({length: 6}, (_, i) => ({
        x: 45 + (i % 2) * 10,
        y: 45 + Math.floor(i / 2) * 10,
        duration: 250,
        timestamp: 2000 + i * 250
      })),
      // Prawy dolny róg (terminal area)
      ...Array.from({length: 4}, (_, i) => ({
        x: 80 - i * 5,
        y: 80 - i * 5,
        duration: 400,
        timestamp: 3500 + i * 400
      }))
    ]
  }
];