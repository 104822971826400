import React, { useState } from 'react';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../api';
import reisloLogo from '../../img/logo-reislo-white.svg';
import { RocketLaunchIcon } from '@heroicons/react/24/solid';
import { UserIcon, EnvelopeIcon, KeyIcon } from '@heroicons/react/24/outline';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(''); // State for first name
  const [lastName, setLastName] = useState(''); // State for last name
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // NEW: Loading state


  const handleRegistration = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      const registrationEndpoint = `${DIRECTUS_INSTANCE}/users`;
      const response = await axios.post(registrationEndpoint, {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        role: '795c4d4b-4258-4f76-a116-70a03cb46d1a',
        status: 'active',
        onboarding_completed: false
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200 && response.data.data) {
        // Get the login token after registration
        const loginResponse = await axios.post(`${DIRECTUS_INSTANCE}/auth/login`, {
          email,
          password
        });

        if (loginResponse.data.data) {
          const token = loginResponse.data.data.access_token;
          const userId = response.data.data.id;
          
          // Store necessary data
          localStorage.setItem('directus_token', token);
          localStorage.setItem('user_id', userId);
          localStorage.setItem('username', `${firstName} ${lastName}`);

          // Redirect to onboarding
          window.location.href = '/onboarding';
        }
      } else {
        setError('Registration failed. Please try again.');
        console.log('Failed Registration Response:', response);
      }
    } catch (error) {
      console.log('Error Response:', error.response);
      const errorMessage = error.response?.data?.errors?.[0]?.message || 'Unknown error occurred.';
      setError(`Registration failed. ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="flex h-dvh flex-grow">
      <div className="flex flex-grow flex-col">
        {/* Logo */}
        <img
          className="h-8 absolute top-8 left-16 z-50"
          src={reisloLogo}
          alt="Reislo logo"
        />
        
        <div className="flex flex-grow flex-row">
          <div className="flex flex-grow flex-row">
            <div className="relative flex w-full justify-center p-10">
              <div className="relative flex max-w-3xl flex-col place-content-between">
                {/* Main Content */}
                <div className="space-y-6 pt-40">
                  {/* Header */}
                  <div className="space-y-1 pb-1">
                    <h1 className="text-2xl tracking-tight text-gray-900">
                      Welcome to Reislo!
                    </h1>
                    <p className="text-sm font-normal text-gray-500">
                      Sign up to start building personalized UX insights in minutes
                    </p>
                  </div>

                  {/* Google Sign Up Button */}
                  <button className="flex w-full items-center justify-center gap-2 rounded border border-gray-300 bg-white px-[20px] py-[10px] text-sm font-semibold text-gray-900 hover:bg-gray-50">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 0C4.477 0 0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10c0-5.523-4.477-10-10-10z" clipRule="evenodd" />
                    </svg>
                    <span>Sign up with Google</span>
                  </button>

                  {/* Divider */}
                  <div className="flex items-center gap-4">
                    <div className="h-px flex-1 bg-gray-200"></div>
                    <p className="text-xs text-gray-500">OR</p>
                    <div className="h-px flex-1 bg-gray-200"></div>
                  </div>

                  {/* Form */}
                  <form className="space-y-4" onSubmit={handleRegistration}>
                    <div className="space-y-3">
                      {/* Name Inputs */}
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          type="text"
                          placeholder="First name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                          className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm text-gray-900 placeholder-gray-400 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        />
                      </div>
                      
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          type="text"
                          placeholder="Last name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                          className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm text-gray-900 placeholder-gray-400 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        />
                      </div>

                      {/* Email Input */}
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          type="email"
                          placeholder="Email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm text-gray-900 placeholder-gray-400 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        />
                      </div>

                      {/* Password Input */}
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <KeyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm text-gray-900 placeholder-gray-400 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        />
                      </div>
                    </div>

                    {/* Error and Success Messages */}
                    {error && <p className="text-sm text-red-600">{error}</p>}
                    {success && <p className="text-sm text-green-600">{success}</p>}

                    {/* Submit Button */}
                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full rounded-md bg-primary-500 py-2 text-sm font-semibold text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50"
                    >
                      {loading ? 'Registering...' : 'Continue'}
                    </button>

                    {/* Login Link */}
                    <p className="text-xs font-medium text-gray-500">
                      Already have an account?{" "}
                      <a href="/login" className="text-primary-500 hover:text-primary-600">
                        Log in
                      </a>
                    </p>
                  </form>
                </div>

                {/* Terms and Privacy */}
                <div>
                  <p className="text-xs font-medium text-gray-500">
                    By signing up for a Reislo account, you agree to our{" "}
                    <a href="/privacy" className="text-gray-900 hover:text-gray-700">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a href="/terms" className="text-gray-900 hover:text-gray-700">
                      Terms of Service
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side Banner */}
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className="absolute inset-0 h-full w-full object-cover object-right"
              src="https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt="Sign In Banner"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
