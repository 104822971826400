import axios from 'axios';
import axiosRetry from 'axios-retry';

export const DIRECTUS_INSTANCE = 'https://panel.reislo.com';

export const DIRECTUS_HEATMAPS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/heatmaps`;

const REACT_APP_GPT_API_KEY = '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';
const SEO_ASSISTANT_ID = 'asst_0ui8Nz9JSXofVHtT4GtcYAIp';

export const apiHeaders = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${REACT_APP_GPT_API_KEY}`,
  'OpenAI-Beta': 'assistants=v2'
};

// Setup axios instance for Directus API
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Set up axios-retry to automatically retry requests
axiosRetry(directusAPI, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => error.response?.status === 429 || error.response?.status === 503,
});

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

directusAPI.interceptors.response.use(response => response, handleApiError);

function handleApiError(error) {
  if (error.response) {
    console.error('Server responded with an error:', error.response.data);
    console.error('Status code:', error.response.status);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
}

//Todo: personas linking 
// Fetch heatmap by ID
export const fetchHeatmapById = async (id) => {
  try {
    const [heatmapResponse, insightsResponse] = await Promise.all([
      directusAPI.get(`${DIRECTUS_HEATMAPS_ENDPOINT}/${id}`, {
        params: {
          fields: ['*', 'linked_personas.personas_id.*']
        }
      }),
      directusAPI.get('https://panel.reislo.com/items/insights', {
        params: {
          filter: { heatmap: { _eq: id } }
        }
      })
    ]);

    const insights = insightsResponse.data.data.map(insight => {
      let coordinates;
      try {
        coordinates = typeof insight.coordinates === 'string'
          ? JSON.parse(insight.coordinates)
          : insight.coordinates || { x: 0, y: 0 };
      } catch (e) {
        console.error('Error parsing coordinates:', e);
        coordinates = { x: 0, y: 0 };
      }

      return {
        id: insight.id,
        Feature: insight.title || insight.description.split(' - ')[0],
        insight_type: insight.insight_type,
        Status: insight.status,
        Observation: insight.description,
        Recommendation: insight.recommendation || '',
        Priority: insight.piority,
        coordinates,
        score_value: insight.score_value
      };
    });

    return {
      ...heatmapResponse.data.data,
      evaluation: insights
    };
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Save or update heatmap data
export const saveHeatmap = async (id, heatmapData) => {
  const token = localStorage.getItem('directus_token');
  if (!token) {
    throw new Error('Authentication token not found. Please log in.');
  }

  const headers = {
    'Authorization': `Bearer ${token}`
  };

  try {
    let body;
    if (heatmapData.coordinates_grid_screenshot && heatmapData.coordinates_grid_screenshot instanceof File) {
      const formData = new FormData();
      formData.append('name', heatmapData.name || '');
      formData.append('evaluation', heatmapData.evaluation || '');
      formData.append('coordinates_grid_screenshot', heatmapData.coordinates_grid_screenshot);
      
      // Handle M2M relationship for linked_personas
      if (heatmapData.linked_personas) {
        const linkedPersonas = Array.isArray(heatmapData.linked_personas) 
          ? heatmapData.linked_personas 
          : [heatmapData.linked_personas];
          
        formData.append('linked_personas', JSON.stringify(
          linkedPersonas.map(id => ({
            personas_id: typeof id === 'object' ? id.personas_id : id
          }))
        ));
      }
      
      headers['Content-Type'] = 'multipart/form-data';
      body = formData;
    } else {
      body = {
        name: heatmapData.name || '',
        evaluation: heatmapData.evaluation || '',
        coordinates_grid_screenshot: heatmapData.coordinates_grid_screenshot,
        linked_personas: heatmapData.linked_personas ? 
          Array.isArray(heatmapData.linked_personas) 
            ? heatmapData.linked_personas.map(id => ({
                personas_id: typeof id === 'object' ? id.personas_id : id
              }))
            : [{ personas_id: heatmapData.linked_personas }]
          : null
      };
    }

    const response = await axios.patch(`${DIRECTUS_HEATMAPS_ENDPOINT}/${id}`, body, { headers });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Evaluate heatmap data using OpenAI GPT-4
export const evaluateHeatmap = async (heatmap) => {
  const { screenshot, name, id, viewMode, type } = heatmap;
  
  console.group('🔍 Heatmap Evaluation Process');
  console.log('📊 Checking for linked personas...');
  
  try {
    // Fetch heatmap data to get linked_personas
    const heatmapResponse = await directusAPI.get(`${DIRECTUS_HEATMAPS_ENDPOINT}/${id}`);
    const heatmapData = heatmapResponse.data.data;
    
    // Fetch personas data if available
    let personasData = [];
    if (heatmapData.linked_personas?.length > 0) {
      console.debug('🎭 Fetching linked personas data...');
      try {
        const personaIds = heatmapData.linked_personas.map(p => 
          typeof p === 'object' ? p.personas_id?.id || p.personas_id : p
        );
        
        const response = await directusAPI.get('https://panel.reislo.com/items/personas', {
          params: {
            filter: { id: { _in: personaIds } },
            fields: ['*']
          }
        });
        personasData = response.data.data;
        console.debug(`✅ Found ${personasData.length} linked personas`);
      } catch (error) {
        console.warn('⚠️ Error fetching linked personas:', error.message);
      }
    } else {
      console.warn('⚠️ No linked personas found for this heatmap');
    }

    // Ensure we have a valid screenshot URL
    if (!screenshot) {
      throw new Error('No screenshot URL provided');
    }

    // Construct the full image URL, ensuring it ends with a valid extension
    const validImageUrl = screenshot.includes('.')
      ? `https://panel.reislo.com/assets/${screenshot}`
      : `https://panel.reislo.com/assets/${screenshot}.png`;

    console.log('Constructed Image URL:', validImageUrl);

    const messages = [
      {
        role: 'assistant',
        content: `I am UX Expert analyzing using BASIC Framework a ${viewMode} view of ${type || 'website'}. I will provide structured UX insights based on the PERSONAS, covering both Good Practices (what's working well) and Areas for Improvement. My job is to provide always 50% good practices and 50% areas for improvement.`
      },
      {
        role: 'user',
        content: `For each insight:
          1. Be extremely specific and detailed in context of the PERSONAS
          2. Focus on one issue per insight
          3. Always reference specific elements from the screenshot
          4. Provide measurable metrics in recommendations when possible
          5. Ensure recommendations are actionable and detailed
          
 Scoring must be justified:
          - 80-100: Excellent implementation, following best practices
          - 60-79: Good implementation with minor room for improvement
          - 40-59: Average implementation needing some improvements
          - 20-39: Poor implementation requiring significant changes
          - 1-19: Critical issues needing immediate attention

          For each BASIC category, focus on:
          BEHAVIORAL: User flows, interaction patterns, navigation clarity
          ACCESSIBLE: WCAG guidelines, keyboard navigation, screen reader compatibility
          SUSTAINABLE: Layout consistency, responsive design, performance
          INCLUSIVE: Content clarity, language use, cultural considerations
          CREDIBLE: Brand alignment, visual hierarchy, professional appearance`
      },
      {
        role: 'user',
        content: `Analyze this ${viewMode} screenshot and provide as many as you can insights in the following JSON format:
        {
          "insights": [
            {
              "title": "Brief, clear title of the issue up to 10 words",
              "description": "Detailed and specified observation of the specific issues and good practices with a focus on the user experience and the impact on the business. Don't write Some or something like that, write the exact issue.",
              "recommendation": "Specific and detailed excact recommendation and solution for the issue, based on informations from the screenshot i.e. label named yxz, green button, header named xyz, etc. also add real data i.e. that this change can increase conversion rate by 10% based on report etc.",
              "priority": "high|medium|low",
              "insight_type": "BEHAVIORAL|ACCESSIBLE|SUSTAINABLE|INCLUSIVE|CREDIBLE|",
              "impact_score": "Score between 1-100 based on severity and potential impact of the issue or good practice"
              }
          ]
        }`
      },
      {
        role: 'user',
        content: `Please dont rate elements you can't check from the screenshot, i.e. you can't check if the text is readable or not, you can't check if the button is clickable or not, etc.`
      },
      {
        role: 'user',
        content: `Please Search through the files you have access and provide source you used to make the recommendation.`
      },
      ...(personasData.length > 0 ? [{
        role: 'user',
        content: `Consider these user personas for the analysis:
${personasData.map(persona => `
Persona: ${persona.name}
Type: ${persona.type}
Age: ${persona.age}
Education: ${persona.education}
Occupation: ${persona.occupation}
Needs: ${persona.needs}
Motivations: ${persona.motivations}
Frustrations: ${persona.frustrations}
`).join('\n')}

Please ensure the analysis takes into account these specific user personas and their characteristics. Focus on their needs, motivations, and potential frustrations when analyzing the interface.`
      }] : []),
      {
        role: 'user',
        content: [
          {
            type: 'image_url',
            image_url: {
              url: validImageUrl,
              detail: 'high'
            }
          }
        ]
      }
    ];

    console.log('📨 Preparing messages for analysis...');
    messages.forEach((message, index) => {
      if (message.role === 'assistant') {
        console.log(`🤖 Assistant message ${index + 1}: Setting up as UX Expert`);
      } else if (message.role === 'user') {
        if (message.content.type === 'image_url') {
          console.log(`🖼️ User message ${index + 1}: Sending screenshot for analysis`);
        } else if (typeof message.content === 'string' && message.content.includes('personas')) {
          console.log(`👥 User message ${index + 1}: Including personas data`);
          console.log(message.content);
        } else if (typeof message.content === 'string') {
          console.log(`💬 User message ${index + 1}: ${message.content}`);
        } else {
          console.log(`📄 User message ${index + 1}:`, JSON.stringify(message.content, null, 2));
        }
      }
    });

    // Add a check for personas data
    if (!personasData || personasData.length === 0) {
      console.warn('⚠️ No personas data available for this analysis');
    }

    // Rest of the function remains the same until the OpenAI calls
    const threadRes = await axios.post('https://api.openai.com/v1/threads', {}, { headers: apiHeaders });
    console.debug('🧵 Created evaluation thread:', threadRes.data.id);

    // Modify the message sending loop
    for (const message of messages) {
      await axios.post(`https://api.openai.com/v1/threads/${threadRes.data.id}/messages`, message, { headers: apiHeaders });
    }
    console.debug('📝 Added all messages to thread');

    console.log('✅ All messages sent successfully to the thread');

    console.log('Running the assistant...');
    const runRes = await axios.post(`https://api.openai.com/v1/threads/${threadRes.data.id}/runs`, {
      assistant_id: SEO_ASSISTANT_ID,
      tool_resources: {
        file_search: {
          vector_store_ids: ['vs_yN16ml89jkatIoZ70D3Qf2PN'] // Replace with your actual vector store ID
        }
      }

    }, {
      headers: apiHeaders
    });

    console.log('Run response:', runRes.data);
    if (!runRes.data || !runRes.data.id) {
      throw new Error('Failed to run assistant');
    }
    const runId = runRes.data.id;

    let runData;
    while (true) {
      runData = await checkRunStatus(threadRes.data.id, runId);
      if (runData.status === 'completed') {
        break;
      } else if (runData.status === 'failed') {
        console.error('Assistant run failed:', runData);
        throw new Error('Assistant run failed.');
      }
      await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before checking again
    }

    console.log('Fetching messages from thread:', threadRes.data.id);
    const messagesRes = await listMessages(threadRes.data.id);
    console.log('Messages response:', messagesRes.data);

    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');
    if (!assistantMessage || !assistantMessage.content || !assistantMessage.content.length) {
      throw new Error('No content generated by the assistant.');
    }

    const content = assistantMessage.content[0].text.value;

    // Clean and parse the content first
    const cleanJson = content
      .replace(/```json\s*/g, '')
      .replace(/```\s*/g, '')
      .trim();

    console.log('Cleaned evaluation content:', cleanJson);

    // Parse the JSON to validate it
    const parsedContent = JSON.parse(cleanJson);
    console.log('Parsed evaluation content:', parsedContent);

    // Save the evaluation response to Directus
    await saveEvaluationToDirectus(id, parsedContent);

    // Return the cleaned and parsed content
    console.groupEnd();
    return parsedContent;
  } catch (error) {
    console.groupEnd();
    throw error;
  }
};

// Function to save the evaluation result to Directus
const saveEvaluationToDirectus = async (id, evaluation) => {
  console.log('Raw evaluation data:', evaluation);
  const token = localStorage.getItem('directus_token');
  if (!token) {
    throw new Error('Authentication token not found. Please log in.');
  }

  try {
    let evaluationData = typeof evaluation === 'string' ? JSON.parse(evaluation) : evaluation;
    console.log('Parsed evaluation data:', evaluationData);

    if (!evaluationData.insights || !Array.isArray(evaluationData.insights)) {
      throw new Error('Invalid evaluation format: missing insights array');
    }

    const insightPromises = evaluationData.insights.map(insight => {
      const insightData = {
        status: 'published',
        insight_type: insight.insight_type.toLowerCase(),
        piority: insight.priority.toLowerCase(),
        title: insight.title,
        description: insight.description,
        recommendation: insight.recommendation,
        heatmap: id,
        coordinates: JSON.stringify({ x: 0, y: 0 }),
        userjourney: null,
        score_value: parseInt(insight.impact_score) || 50 // Use GPT-provided score with fallback
      };

      return axios.post('https://panel.reislo.com/items/insights', insightData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
    });

    await Promise.all(insightPromises);
    return { success: true };
  } catch (error) {
    console.error('Failed to save insights:', error);
    if (error.response?.data) {
      console.error('API Error details:', error.response.data);
    }
    throw error;
  }
};

export const checkRunStatus = async (threadId, runId) => {
  try {
    const res = await axios.get(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, { headers: apiHeaders });
    // Only log status changes
    if (res.data.status !== checkRunStatus.lastStatus) {
      console.debug(`🔄 Run status: ${res.data.status}`);
      checkRunStatus.lastStatus = res.data.status;
    }
    return res.data;
  } catch (error) {
    console.error('❌ Error checking run status:', error.message);
    throw error;
  }
};
checkRunStatus.lastStatus = null;

export const listMessages = async (threadId) => {
  try {
    const res = await axios.get(`https://api.openai.com/v1/threads/${threadId}/messages`, { headers: apiHeaders });
    console.log('List messages response:', res.data);
    return res.data;
  } catch (error) {
    console.error('Error listing messages:', error);
    throw error;
  }
};

export const updateInsightCoordinates = async (insightId, coordinates) => {
  const token = localStorage.getItem('directus_token');
  if (!token) {
    throw new Error('Authentication token not found. Please log in.');
  }

  try {
    const response = await directusAPI.patch(`https://panel.reislo.com/items/insights/${insightId}`, {
      coordinates: JSON.stringify(coordinates)
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const updateInsight = async (insightId, insightData) => {
  const token = localStorage.getItem('directus_token');
  if (!token) {
    throw new Error('Authentication token not found');
  }

  try {
    // Ensure coordinates is a string
    if (insightData.coordinates && typeof insightData.coordinates !== 'string') {
      insightData.coordinates = JSON.stringify(insightData.coordinates);
    }

    // Convert score_value to number if it exists
    if (insightData.score_value) {
      insightData.score_value = parseInt(insightData.score_value);
    }

    const response = await directusAPI.patch(
      `/items/insights/${insightId}`,
      {
        ...insightData,
        // Ensure these fields are properly formatted
        piority: insightData.priority?.toLowerCase() || insightData.piority, // Handle both field names
        score_value: insightData.score_value || null
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating insight:', error);
    if (error.response?.data) {
      console.error('API Error details:', error.response.data);
    }
    throw error;
  }
};

export const deleteInsight = async (insightId) => {
  try {
    const response = await fetch(`${DIRECTUS_INSTANCE}/items/insights/${insightId}`, {
      method: 'DELETE',
      headers: await apiHeaders(),
    });

    if (!response.ok) {
      throw new Error(`Failed to delete insight: ${response.statusText}`);
    }

    return true;
  } catch (error) {
    console.error('Error in deleteInsight:', error);
    throw error;
  }
};

export const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await directusAPI.post('/auth/refresh', {
      refresh_token: refreshToken
    });

    if (response.data.access_token) {
      localStorage.setItem('directus_token', response.data.access_token);
      if (response.data.refresh_token) {
        localStorage.setItem('refresh_token', response.data.refresh_token);
      }
    }

    return response.data;
  } catch (error) {
    console.error('Token refresh failed:', error);
    localStorage.removeItem('directus_token');
    localStorage.removeItem('refresh_token');
    throw error;
  }
};

const fetchPersonasData = async (personaIds) => {
  try {
    const response = await directusAPI.get('https://panel.reislo.com/items/personas', {
      params: {
        filter: { id: { _in: personaIds } },
        fields: ['*']
      }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching personas data:', error);
    throw error;
  }
};