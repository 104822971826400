import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DIRECTUS_INSIGHTS_ENDPOINT } from '../../api';
import { XMarkIcon } from '@heroicons/react/24/outline';

export function InsightSelector({ isOpen, onClose, onSelect, type, currentInsights = [] }) {
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      fetchInsights();
    }
  }, [isOpen]);

  const fetchInsights = async () => {
    try {
      const token = localStorage.getItem('directus_token');
      const response = await axios.get(DIRECTUS_INSIGHTS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: {
            insight_type: { _eq: type === 'Painpoint' ? 'pain' : 'success' },
            id: { _nin: currentInsights }
          },
          sort: ['-date_created'],
          fields: ['*', 'project.project_name', 'userjourney.user_journey_name']
        }
      });
      setInsights(response.data.data);
    } catch (error) {
      console.error('Failed to fetch insights:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div 
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50"
          onClick={onClose}
        />
      )}
      
      <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-[51]`}>
        <div className="h-full flex flex-col">
          <div className="px-4 py-6 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-medium">Select {type}</h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            ) : (
              <div className="space-y-2">
                {insights.map(insight => (
                  <div
                    key={insight.id}
                    onClick={() => onSelect(insight)}
                    className="p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-all"
                  >
                    <h3 className="text-base font-medium text-gray-900">{insight.description}</h3>
                    {insight.project && (
                      <p className="text-sm text-gray-500 mt-1">Project: {insight.project.project_name}</p>
                    )}
                    <div className="flex items-center gap-2 mt-2">
                      <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                        insight.insight_type === 'pain' ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'
                      }`}>
                        {insight.insight_type === 'pain' ? 'Pain' : 'Gain'}
                      </span>
                      <span className="text-xs text-gray-500">{insight.piority}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
} 