import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { DIRECTUS_HEATMAPS_ENDPOINT, DIRECTUS_INSTANCE } from '../../api';
import ContentHeader from '../Atoms/contentHeader';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/react/20/solid';

// Add this directusAPI configuration
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
});

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// EmptyState component
const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <svg
      className="mx-auto h-16 w-16 text-primary-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
      />
    </svg>
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Let's start with a new heatmap</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new heatmap.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New Heatmap
      </button>
    </div>
  </div>
);

// Heatmap skeleton loader component
const HeatmapSkeleton = () => (
  <tr className="animate-pulse">
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-24"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-24"></div>
    </td>
  </tr>
);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
};

const getUserName = async (userId) => {
  try {
    const response = await directusAPI.get(`/users/${userId}`, {
      params: {
        fields: ['first_name', 'last_name', 'email']
      }
    });
    return response.data.data.first_name 
      ? `${response.data.data.first_name} ${response.data.data.last_name}`
      : response.data.data.email;
  } catch (error) {
    console.error('Error fetching user:', error);
    return 'Unknown User';
  }
};

function HeatmapsTable() {
  const [heatmaps, setHeatmaps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userNames, setUserNames] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const title = 'Heatmaps';
  const description = 'Navigate through the cards to view specific information or to make updates to existing heatmaps.';
  const primaryAction = 'Create New Heatmap';
  const itemsPerPage = 50;

  const primaryClick = () => navigate('/predictions/create-heatmap');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(DIRECTUS_HEATMAPS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { user_created: userId },
            sort: '-date_created',
            page: currentPage,
            limit: itemsPerPage,
          }
        });
        setHeatmaps(response.data.data);
        setTotalPages(Math.ceil(response.data.meta.total_count / itemsPerPage));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching heatmaps:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token, currentPage]);

  useEffect(() => {
    const fetchUserNames = async () => {
      const userIds = [...new Set(heatmaps.map(h => h.user_created))];
      const names = {};
      for (const userId of userIds) {
        if (userId) {
          names[userId] = await getUserName(userId);
        }
      }
      setUserNames(names);
    };

    if (heatmaps.length > 0) {
      fetchUserNames();
    }
  }, [heatmaps]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="lg:pl-24">
      <div className="mx-auto">
        {heatmaps.length > 0 && !loading && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={primaryClick}
          />
        )}

        <div className="mt-8 flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Owner</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created Date</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Array.from({ length: 6 }).map((_, index) => (
                      <HeatmapSkeleton key={index} />
                    ))}
                  </tbody>
                </table>
              ) : heatmaps.length === 0 ? (
                <div className="col-span-full h-full flex items-center justify-center">
                  <EmptyState onPrimaryClick={primaryClick} />
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Owner</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created Date</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {heatmaps.map((heatmap) => (
                      <tr 
                        key={heatmap.id} 
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => navigate(`/predictions/${heatmap.id}`)}
                      >
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {heatmap.title.length > 30 
                            ? `${heatmap.title.substring(0, 30)}...` 
                            : heatmap.title}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {heatmap.user_created ? userNames[heatmap.user_created] || 'Loading...' : 'N/A'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {formatDate(heatmap.date_created)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{' '}
                <span className="font-medium">{Math.min(currentPage * itemsPerPage, heatmaps.length)}</span> of{' '}
                <span className="font-medium">{heatmaps.length}</span> results
              </p>
            </div>
            <div>
              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                  </svg>
                </button>
                {[...Array(totalPages).keys()].map((page) => (
                  <button
                    key={page + 1}
                    onClick={() => handlePageChange(page + 1)}
                    className={classNames(
                      page + 1 === currentPage
                        ? 'relative z-10 inline-flex items-center bg-primary-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
                        : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                      'hidden md:inline-flex'
                    )}
                  >
                    {page + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeatmapsTable;