import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_USER_JOURNEYS_ENDPOINT } from '../../api'; // Ensure this is correctly imported
import { UserIcon, PlusIcon } from '@heroicons/react/20/solid';

const title = 'User Journeys';
const description = 'Below is a list of all user journeys created for your projects. Each journey maps out the customer experience and helps identify key touchpoints, painpoints, and opportunities for improvement.';
const primaryAction = 'Create New User Journey';

const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <UserIcon className="mx-auto h-12 w-12 text-gray-400" />
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Create First User Journey</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new user journey. Here soon will be cool marketing material.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New User Journey
      </button>
    </div>
  </div>
);

const SkeletonRow = () => (
  <tr className="animate-pulse">
    <td className="whitespace-nowrap py-4 pl-4 pr-3">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="flex -space-x-2 overflow-hidden">
        {[1, 2, 3].map((i) => (
          <div key={i} className="h-8 w-8 rounded-full bg-gray-200"></div>
        ))}
      </div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-1/3"></div>
    </td>
  </tr>
);

function ProjectsTable() {
  const [projects, setProjects] = useState([]); // State for storing projects data
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id'); // Assuming you store user ID in local storage
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(DIRECTUS_USER_JOURNEYS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userId },
            fields: [
              '*',
              'project.project_name',
              'owner.first_name',
              'owner.last_name',
              'owner.email',
              'date_created',
              'linked_project.project_name',
              'linked_personas.personas_id.name',
              'linked_personas.personas_id.id',
              'linked_personas.personas_id.avatar.id'
            ]
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [userId, token]); // Ensure dependencies are correctly listed for re-fetching when they change

  return (
    <div className="px-4 sm:px-0 lg:pl-24">
      <div className="max-w-7xl mx-auto">
        {projects.length > 0 && (
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto mt-8">
              <h1 className="text-4xl font-medium leading-6 text-gray-900 mt-8">{title}</h1>
              <p className="mt-6 text-base text-gray-600">
                {description}
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                onClick={() => navigate('/user-journeys/create')}
                className="block rounded-full bg-primary-500 px-6 py-2 text-center text-base font-medium text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                {primaryAction}
              </button>
            </div>
          </div>
        )}
        <div className="mt-8 flow-root">
          <div className="mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">User Journey Name</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Linked Project</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Linked Personas</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created By</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created Date</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <SkeletonRow key={index} />
                    ))}
                  </tbody>
                </table>
              ) : projects.length === 0 ? (
                <EmptyState onPrimaryClick={() => navigate('/user-journeys/create')} />
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">User Journey Name</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Linked Project</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Linked Personas</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created By</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created Date</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {projects.map((userjourney) => (
                      <tr key={userjourney.id} onClick={() => navigate(`/user-journeys/${userjourney.id}`)} className="cursor-pointer hover:bg-gray-100">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {userjourney.user_journey_name}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {userjourney.linked_project?.project_name || '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {userjourney.linked_personas && userjourney.linked_personas.length > 0 ? (
                            <div className="flex -space-x-2 overflow-hidden">
                              {userjourney.linked_personas.map(relation => (
                                <div key={relation.personas_id?.id} className="relative group">
                                  <img
                                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                                    src={relation.personas_id?.avatar?.id 
                                      ? `https://panel.reislo.com/assets/${relation.personas_id.avatar.id}?width=100&height=100&fit=cover&format=webp`
                                      : 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                                    }
                                    alt={relation.personas_id?.name}
                                  />
                                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                    {relation.personas_id?.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {userjourney.owner?.first_name 
                            ? `${userjourney.owner.first_name} ${userjourney.owner.last_name}`
                            : userjourney.owner?.email || '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {userjourney.date_created 
                            ? new Date(userjourney.date_created).toLocaleDateString()
                            : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsTable;
