import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const AutoSaveIndicator = ({ isSaving }) => {
  return (
    <div className="flex items-center text-sm">
      {isSaving ? (
        <>
          <ArrowPathIcon className="h-4 w-4 text-gray-400 animate-spin mr-1.5" />
          <span className="text-gray-500">Saving...</span>
        </>
      ) : (
        <>
          <CheckCircleIcon className="h-4 w-4 text-green-500 mr-1.5" />
          <span className="text-gray-500">Saved</span>
        </>
      )}
    </div>
  );
};

export default AutoSaveIndicator;