import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useData } from '../../api/DataContext';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { DIRECTUS_USER_JOURNEYS_ENDPOINT, DIRECTUS_INSIGHTS_ENDPOINT,DIRECTUS_INSTANCE, DIRECTUS_USER_JOURNEY_PHASES_ENDPOINT, DIRECTUS_JOURNEY_PHASE_PAINPOINTS_ENDPOINT, DIRECTUS_JOURNEY_PHASE_GAINS_ENDPOINT } from '../../api';
import JourneyItemDrawer from './JourneyItemDrawer';
import PhaseDrawer from './PhaseDrawer';
import { InsightViewDrawer } from './InsightViewDrawer';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  useDroppable,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { InsightSelector } from './InsightSelector';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { CSS } from '@dnd-kit/utilities';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

function SortablePhase({ id, phase, index, onPhaseClick }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      onPhaseClick(index);
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`w-80 border-l border-t  border-grey-100 bg-white pl-3 pt-2  text-sm
        hover:bg-primary-50 transition-all duration-200 relative`}
    >
      <div {...attributes} {...listeners} className="absolute inset-0 cursor-move" />
      <div 
        onClick={handleClick}
        className="px-2 pt-1.5 cursor-pointer relative z-10"
      >
        {phase.phase_name}
      </div>
    </div>
  );
}

function SortableItem({ id, item, type, handleInsightClick, phaseIndex }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="relative flex items-center gap-2 bg-white p-2 rounded border cursor-move"
    >
      <div 
        className="flex-1 text-sm text-gray-800"
        onClick={() => handleInsightClick(item.insight_data, phaseIndex + 1)}
        style={{ cursor: 'pointer' }}
      >
        {type === 'painpoint' ? item.insight_data?.description : item.insight_data?.description}
      </div>
      <div {...listeners} className="cursor-grab">
        <Bars3Icon className="h-5 w-5 text-gray-400" />
      </div>
    </div>
  );
}

function DroppableContainer({ children, id, phaseIndex }) {
  const { isOver, setNodeRef } = useDroppable({ 
    id: `container-${id}-${phaseIndex}`,
    data: {
      phaseIndex,
      type: id.startsWith('painpoint') ? 'painpoint' : 'gain'
    }
  });
  
  return (
    <div
      ref={setNodeRef}
      className={`flex flex-col gap-1 w-80 p-1.5 transition-all duration-200 border-l border-t pb-16 border-grey-100 bg-white
        ${isOver ? 'border border-blue-400 bg-blue-50' : ''}`}
    >
      {children}
    </div>
  );
}

function CustomerJourneyTimeline() {
  const { id } = useParams();
  const { journeys, setJourneys, loading, setLoading, error, setError } = useData();
  const userID = localStorage.getItem('user_id');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState(null);
  const [activePhaseIndex, setActivePhaseIndex] = useState(null);
  const [editData, setEditData] = useState(null);
  const [isPhaseDrawerOpen, setIsPhaseDrawerOpen] = useState(false);
  const [activePhase, setActivePhase] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [isInsightSelectorOpen, setIsInsightSelectorOpen] = useState(false);
  const [selectedPhaseIndex, setSelectedPhaseIndex] = useState(null);
  const [selectorType, setSelectorType] = useState(null);
  const navigate = useNavigate();
  const [selectedInsight, setSelectedInsight] = useState(null);
  const [isInsightViewDrawerOpen, setIsInsightViewDrawerOpen] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState(null);
  const [isDragLoading, setIsDragLoading] = useState(false);
  const queryClient = useQueryClient();

  const saveJourney = async (updatedJourney) => {
    try {
      const token = localStorage.getItem('directus_token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const phases = updatedJourney.phase.map((phase, index) => ({
        phase_name: phase.phase_name,
        emotional_curve: phase.emotional_curve,
        order_value: index + 1,
        painpoints: (phase.painpoints || []).map(p => ({
          insight_id: typeof p.insight_id === 'string' ? p.insight_id : p.insight_id?.id
        })),
        gains: (phase.gains || []).map(g => ({
          insight_id: typeof g.insight_id === 'string' ? g.insight_id : g.insight_id?.id
        }))
      }));

      await axios.patch(
        `${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${updatedJourney.id}`,
        {
          user_journey_name: updatedJourney.user_journey_name,
          user_journey_description: updatedJourney.user_journey_description,
          phase: phases
        },
        config
      );

      return updatedJourney;
    } catch (error) {
      console.error('Save Error:', error);
      throw new Error('Failed to save changes');
    }
  };

  const fetchJourney = async () => {
    try {
      const token = localStorage.getItem('directus_token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const journeyResponse = await axios.get(
        `${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${id}`,
        config
      );

      const journeyData = journeyResponse.data.data;

      // Collect all insight IDs
      const insightIds = new Set();
      journeyData.phase.forEach(phase => {
        phase.painpoints?.forEach(p => p.insight_id && insightIds.add(p.insight_id));
        phase.gains?.forEach(g => g.insight_id && insightIds.add(g.insight_id));
      });

      if (insightIds.size > 0) {
        const insightsResponse = await axios.get(
          `${DIRECTUS_INSIGHTS_ENDPOINT}?filter[id][_in]=${Array.from(insightIds).join(',')}`,
          config
        );

        const insightsMap = new Map(
          insightsResponse.data.data.map(insight => [insight.id, insight])
        );

        journeyData.phase = journeyData.phase.map(phase => ({
          ...phase,
          painpoints: phase.painpoints?.map(p => ({
            ...p,
            insight_data: insightsMap.get(p.insight_id)
          })) || [],
          gains: phase.gains?.map(g => ({
            ...g,
            insight_data: insightsMap.get(g.insight_id)
          })) || []
        }));
      }

      return journeyData;
    } catch (err) {
      console.error('Fetch Error:', err);
      throw new Error('Failed to fetch journey details');
    }
  };

  const { 
    data: currentJourney, 
    isLoading,
    isError,
    error: queryError
  } = useQuery({
    queryKey: ['journey', id],
    queryFn: fetchJourney,
  });

  const journeyMutation = useMutation({
    mutationFn: saveJourney,
    onMutate: async (newJourney) => {
      await queryClient.cancelQueries(['journey', id]);
      const previousJourney = queryClient.getQueryData(['journey', id]);
      queryClient.setQueryData(['journey', id], newJourney);
      return { previousJourney };
    },
    onError: (err, newJourney, context) => {
      queryClient.setQueryData(['journey', id], context.previousJourney);
      console.error('Save failed:', err);
    },
    onSettled: () => {
      setIsDragLoading(false);
    },
  });

  const handleInsightClick = useCallback((insightData, phaseId) => {
    setSelectedInsight(insightData);
    setSelectedPhaseId(phaseId);
    setIsInsightViewDrawerOpen(true);
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const openDrawer = (type, phaseIndex, existingData = null) => {
    setDrawerType(type);
    setActivePhaseIndex(phaseIndex);
    setEditData(existingData);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerType(null);
    setActivePhaseIndex(null);
    setEditData(null);
  };

  const handleSaveItem = async (phaseIndex, itemType, itemData) => {
    const updatedJourney = { ...currentJourney };
    if (itemType === 'Painpoint') {
      if (editData) {
        const painpointIndex = updatedJourney.phase[phaseIndex].painpoints.findIndex(
          p => p.single_painpoint === editData.content
        );
        if (painpointIndex !== -1) {
          updatedJourney.phase[phaseIndex].painpoints[painpointIndex] = {
            single_painpoint: itemData.content,
            name: itemData.name
          };
        }
      } else {
        updatedJourney.phase[phaseIndex].painpoints.push({
          single_painpoint: itemData.content,
          name: itemData.name
        });
      }
    } else if (itemType === 'Gain') {
      if (!updatedJourney.phase[phaseIndex].gains) {
        updatedJourney.phase[phaseIndex].gains = [];
      }
      if (editData) {
        const gainIndex = updatedJourney.phase[phaseIndex].gains.findIndex(
          g => g.single_gain === editData.content
        );
        if (gainIndex !== -1) {
          updatedJourney.phase[phaseIndex].gains[gainIndex] = {
            single_gain: itemData.content,
            name: itemData.name
          };
        }
      } else {
        updatedJourney.phase[phaseIndex].gains.push({
          single_gain: itemData.content,
          name: itemData.name
        });
      }
    }
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const addPainPoint = async (phaseIndex) => {
    const updatedJourney = { ...currentJourney };
    updatedJourney.phase[phaseIndex].painpoints.push({ single_painpoint: "New Pain Point" });
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const addGain = async (phaseIndex) => {
    const updatedJourney = { ...currentJourney };
    if (!updatedJourney.phase[phaseIndex].gains) {
      updatedJourney.phase[phaseIndex].gains = [];
    }
    updatedJourney.phase[phaseIndex].gains.push({ single_gain: "New Gain" });
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const addNewPhase = async () => {
    const updatedJourney = { ...currentJourney };
    const newPhase = {
      phase_name: "New Phase",
      emotional_curve: 0,
      painpoints: [],
      gains: [],
      order_value: updatedJourney.phase.length + 1
    };
    
    updatedJourney.phase.push(newPhase);
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const truncateText = (text, limit) => {
    if (!text) return '';
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  const handlePhaseUpdate = async (phaseData) => {
    const updatedJourney = { ...currentJourney };
    updatedJourney.phase[activePhase] = {
      ...updatedJourney.phase[activePhase],
      ...phaseData
    };
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const handlePhaseDelete = async () => {
    const updatedJourney = { ...currentJourney };
    updatedJourney.phase.splice(activePhase, 1);
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (!active || !over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const [activeType, sourcePhaseIndex, itemIndex] = activeId.split('-');
    const targetContainer = over.id;
    const [_, targetType, targetPhaseIndex] = targetContainer.split('-');

    // Create a local copy of the journey
    const updatedJourney = { ...currentJourney };

    // Handle insight movement between phases
    if ((activeType === 'painpoint' || activeType === 'gain') && targetContainer.startsWith('container-')) {
      setIsDragLoading(true); // Start loading state
      
      const sourcePhase = updatedJourney.phase[sourcePhaseIndex];
      const targetPhase = updatedJourney.phase[targetPhaseIndex];
      
      const sourceArray = activeType === 'painpoint' ? sourcePhase.painpoints : sourcePhase.gains;
      const targetArray = activeType === 'painpoint' ? targetPhase.painpoints : targetPhase.gains;

      if (!targetArray) {
        targetPhase[activeType === 'painpoint' ? 'painpoints' : 'gains'] = [];
      }

      // Move the item
      const [movedItem] = sourceArray.splice(itemIndex, 1);
      targetPhase[activeType === 'painpoint' ? 'painpoints' : 'gains'].push(movedItem);

      try {
        setIsDragLoading(true);
        await journeyMutation.mutateAsync(updatedJourney);
      } catch (error) {
        console.error('Failed to move insight:', error);
      }
    }
  };

  const convertItem = (item, fromType, toType) => {
    if (fromType === 'painpoint' && toType === 'gain') {
      return {
        single_gain: item.single_painpoint,
        name: item.name
      };
    } else if (fromType === 'gain' && toType === 'painpoint') {
      return {
        single_painpoint: item.single_gain,
        name: item.name
      };
    }
    return item;
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleInputChange = async (e) => {
    const updatedJourney = { ...currentJourney, user_journey_name: e.target.value };
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const openInsightSelector = (type, phaseIndex) => {
    setSelectorType(type);
    setSelectedPhaseIndex(phaseIndex);
    setIsInsightSelectorOpen(true);
  };

  const handleInsightSelect = async (insight) => {
    try {
      console.log('Selected insight:', insight);
      console.log('Current phase index:', selectedPhaseIndex);

      const updatedJourney = { ...currentJourney };
      const phase = updatedJourney.phase[selectedPhaseIndex];

      // Fetch complete insight data
      const token = localStorage.getItem('directus_token');
      const response = await axios.get(`${DIRECTUS_INSIGHTS_ENDPOINT}/${insight.id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const fullInsightData = response.data.data;

      // Create the connection with full insight data
      const connectionObject = {
        insight_id: insight.id,
        insight_data: fullInsightData // Add the full insight data
      };

      // Add to appropriate array
      if (selectorType === 'Painpoint') {
        if (!phase.painpoints) phase.painpoints = [];
        phase.painpoints.push(connectionObject);
      } else {
        if (!phase.gains) phase.gains = [];
        phase.gains.push(connectionObject);
      }

      await journeyMutation.mutateAsync(updatedJourney);
      setIsInsightSelectorOpen(false);

    } catch (error) {
      console.error('Failed to add insight:', error);
    }
  };


  const handleDragOver = (event) => {
    const { active, over } = event;
    
    if (!active || !over) return;

    const activeId = active.id;
    const overId = over.id;
    
    if (activeId === overId) return;

    const [activeType, activePhaseIndex] = activeId.split('-');
    const [overType, overPhaseIndex] = overId.split('-');

    // Allow dragging between phases for insights
    if (activeType === 'painpoint' || activeType === 'gain') {
      const targetContainer = `container-${activeType}-${overPhaseIndex}`;
      if (over.id === targetContainer) {
        // Valid drop target
        return;
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!currentJourney?.phase) return <div>No journey data available.</div>;

  const chartData = {
    labels: currentJourney.phase.map(phase => phase.phase_name),
    datasets: [
      {
        label: 'Emotional Curve',
        data: currentJourney.phase.map(phase => phase.emotional_curve),
        fill: false,
        borderColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 220);
          gradient.addColorStop(0, '#3ED193');
          gradient.addColorStop(0.25, '#3ED193');
          gradient.addColorStop(0.5, '#EADC66');
          gradient.addColorStop(0.75, '#E7ABAB');
          gradient.addColorStop(1, '#E7ABAB');
          return gradient;
        },
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: -3,
        max: 3,
        grid: {
          display: true
        },
        ticks: {
          stepSize: 1
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const chartWidth = currentJourney.phase.length * 320;

  return (
    <div className="bg-white pt-2 lg:pl-24 relative">
      {isDragLoading && (
        <div className="absolute inset-0 bg-white/50 z-50 flex items-center justify-center">
          <div className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Saving...
          </div>
        </div>
      )}

      <div className="border-b border-gray-200 py-4">
        <div className="flex w-full flex-row items-center">
          <div className="relative flex-none">
            <div className="relative flex max-w-full">
              <input
                value={currentJourney.user_journey_name}
                onChange={handleInputChange}
                placeholder="Enter a name..."
                className="text-lg font-medium leading-7 text-gray-900 sm:text-lg sm:tracking-tight w-full shadow-none bg-transparent border-none focus:outline-none"
              />
            </div>
          </div>

          <div className="flex items-center gap-2 ml-4">
            <button
              className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
              aria-label="Show details"
              type="button"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </button>

            <button
              className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
              aria-label="Favorite journey"
              type="button"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 0 00.951-.69l1.519-4.674z" />
              </svg>
            </button>

            <button
              className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
              aria-label="Journey actions"
              type="button"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
              </svg>
            </button>
          </div>

          <div className="flex w-full flex-row items-center gap-4">
            <div className="ml-auto mr-0 flex items-center gap-4">
              {/* Linked Personas Avatars */}
              {currentJourney.linked_personas && currentJourney.linked_personas.length > 0 && (
                <div className="flex -space-x-2 overflow-hidden">
                  {currentJourney.linked_personas.map(relation => (
                    <div key={relation.personas_id?.id || Math.random()} className="relative group">
                      <img
                        className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                        src={relation.personas_id?.avatar?.id 
                          ? `https://panel.reislo.com/assets/${relation.personas_id.avatar.id}?width=256&height=256&fit=cover&format=webp`
                          : relation.personas_id?.avatar
                            ? `https://panel.reislo.com/assets/${relation.personas_id.avatar}?width=256&height=256&fit=cover&format=webp`
                            : 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                        }
                        alt={relation.personas_id?.name || 'Avatar'}
                        onError={(e) => {
                          console.log('Image load error:', e);
                          e.target.src = 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';
                        }}
                      />
                      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                        {relation.personas_id?.name || 'Unknown'}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* User Avatar */}
              <div className="flex items-center">
                <div className="h-8 w-8 rounded-full bg-indigo-600 flex items-center justify-center text-white text-sm">
                  {userID?.substring(0, 2).toUpperCase()}
                </div>
              </div>

              {/* Share Button */}
              <button
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                type="button"
              >
                <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                </svg>
                Share
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="customer-journey-map overflow-x-auto mt-6 min-h-screen">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
        >
          <div className="flex min-w-max  ">
            <div className="min-w-40 bg-white pl-3 text-sm rounded-tl-lg  border-l pt-3 border-t border-grey-100">Phases</div>
            <SortableContext
              items={currentJourney.phase.map((_, index) => `phase-${index}`)}
              strategy={horizontalListSortingStrategy}
            >
              {currentJourney.phase.map((phase, index) => (
                <SortablePhase
                  key={`phase-${index}`}
                  id={`phase-${index}`}
                  phase={phase}
                  index={index}
                  onPhaseClick={(index) => {
                    setActivePhase(index);
                    setIsPhaseDrawerOpen(true);
                  }}
                />
              ))}
            </SortableContext>
            <div 
              onClick={addNewPhase}
              className="w-40 bg-grey-100 p-3 rounded-lg hover:bg-primary-200 cursor-pointer transition-colors duration-200"
            >
              Add Phase +
            </div>
          </div>

          <div className="flex min-w-max ">
            <div className="min-w-40 bg-white p-3 text-sm border-t border-grey-100 border-l">Emotional Curve</div>
            <div className="border-l border-t border-grey-100 pl-4 py-4" style={{ height: '300px', width: `${chartWidth}px` }}>
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>

          <div className="flex min-w-max">
            <div className="min-w-40 bg-white p-3 text-sm border-l border-t border-grey-100">Painpoints</div>
            {currentJourney.phase.map((phase, phaseIndex) => (
              <DroppableContainer 
                key={phaseIndex} 
                id={`painpoint`} 
                phaseIndex={phaseIndex}
              >
                <SortableContext
                  items={(phase.painpoints || []).map((_, index) => `painpoint-${phaseIndex}-${index}`)}
                  strategy={verticalListSortingStrategy}
                >
                  {(phase.painpoints || []).map((painPoint, index) => (
                    <SortableItem
                      key={`painpoint-${phaseIndex}-${index}`}
                      id={`painpoint-${phaseIndex}-${index}`}
                      item={painPoint}
                      type="painpoint"
                      handleInsightClick={handleInsightClick}
                      phaseIndex={phaseIndex}
               
                    />
                  ))}
                </SortableContext>
                <button 
                  onClick={() => openInsightSelector('Painpoint', phaseIndex)} 
                  className="opacity-0 hover:opacity-100 mt-2 p-2 text-sm text-neutral-900 bg-neutral-200 rounded border border-dashed border-neutral-400"
                >
                  + Add Painpoint
                </button>
              </DroppableContainer>
            ))}
          </div>

          <div className="flex min-w-max">
            <div className="min-w-40 bg-white p-3 text-sm border-l border-t border-grey-100">Gains</div>
            {currentJourney.phase.map((phase, phaseIndex) => (
              <DroppableContainer 
                key={phaseIndex} 
                id={`gain`} 
                phaseIndex={phaseIndex}
              >
                <SortableContext
                  items={(phase.gains || []).map((_, index) => `gain-${phaseIndex}-${index}`)}
                  strategy={verticalListSortingStrategy}
                >
                  {(phase.gains || []).map((gain, index) => (
                    <SortableItem
                      key={`gain-${phaseIndex}-${index}`}
                      id={`gain-${phaseIndex}-${index}`}
                      item={gain}
                      type="gain"
                      handleInsightClick={handleInsightClick}
                      phaseIndex={phaseIndex}
                      className="bg-green-50"
                    />
                  ))}
                </SortableContext>
                <button 
                  onClick={() => openInsightSelector('Gain', phaseIndex)} 
                  className="opacity-0 hover:opacity-100 mt-2 p-2 text-sm text-neutral-900 bg-neutral-200 rounded border border-dashed border-neutral-400"
                >
                  + Add Gain
                </button>
              </DroppableContainer>
            ))}
          </div>

          <InsightSelector
            isOpen={isInsightSelectorOpen}
            onClose={() => setIsInsightSelectorOpen(false)}
            onSelect={handleInsightSelect}
            type={selectorType}
            currentInsights={currentJourney.phase[selectedPhaseIndex]?.[selectorType === 'Painpoint' ? 'painpoints' : 'gains']?.map(item => item.insight_id) || []}
          />

          <DragOverlay>
            {activeId && (
              <div className={`
                ${activeId.startsWith('phase-') 
                  ? 'w-80 bg-primary-50 pl-3 pt-3' 
                  : activeId.startsWith('painpoint')
                    ? 'white border-red-100'
                    : 'bg-white border-green-100'
                } text-sm px-3 py-2 rounded-lg shadow-lg opacity-80`}
              >
                {(() => {
                  if (activeId.startsWith('phase-')) {
                    const index = parseInt(activeId.split('-')[1]);
                    return currentJourney.phase[index]?.phase_name || 'Phase';
                  }
                  
                  const [type, phaseIndex, itemIndex] = activeId.split('-');
                  const phase = currentJourney.phase[phaseIndex];
                  
                  if (type === 'painpoint' && phase?.painpoints?.[itemIndex]) {
                    return truncateText(phase.painpoints[itemIndex].single_painpoint, 40);
                  } else if (type === 'gain' && phase?.gains?.[itemIndex]) {
                    return truncateText(phase.gains[itemIndex].single_gain, 40);
                  }
                  return '';
                })()}
              </div>
            )}
          </DragOverlay>
        </DndContext>
      </div>

      <JourneyItemDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        onSave={handleSaveItem}
        itemType={drawerType}
        phaseIndex={activePhaseIndex}
        editData={editData}
      />

      <PhaseDrawer
        isOpen={isPhaseDrawerOpen}
        onClose={() => {
          setIsPhaseDrawerOpen(false);
          setActivePhase(null);
        }}
        onSave={handlePhaseUpdate}
        onDelete={handlePhaseDelete}
        phaseData={activePhase !== null ? currentJourney.phase[activePhase] : null}
      />

<InsightViewDrawer
  isOpen={isInsightViewDrawerOpen}
  onClose={() => {
    setIsInsightViewDrawerOpen(false);
    setSelectedInsight(null);
  }}
  insight={selectedInsight}
  phaseId={selectedPhaseId}
/>
    </div>
  );
}

export default CustomerJourneyTimeline;