import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';

const HeatmapNavigation = ({
  onBack,
  currentIndex,
  totalItems,
  onNavigate,
  loading,
  lastSaved
}) => {
  const navigate = useNavigate();

  const getSaveStatus = () => {
    if (loading) {
      return 'Saving...';
    }
    if (lastSaved) {
      return `Saved ${lastSaved}`;
    }
    return 'Not saved yet';
  };

  return (
    <div className="sticky top-0 z-10 bg-white shadow-sm pl-16">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="cursor-pointer" onClick={onBack}>
            <div className="border-r border-secondary-100 py-4 pr-6">
              <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>

          <div className="flex gap-2 items-center">
            {loading ? (
              <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-secondary-500" role="status">
                <span className="visually-hidden"></span>
              </div>
            ) : (
              <CloudArrowUpIcon 
                className={`h-6 w-6 ${lastSaved ? 'text-green-500' : 'text-secondary-500'}`} 
                aria-hidden="true" 
              />
            )}
            <span className={`text-sm ${loading ? 'text-secondary-500' : lastSaved ? 'text-green-600' : 'text-neutral-600'}`}>
              {getSaveStatus()}
            </span>
          </div>

          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-500">{currentIndex + 1} / {totalItems}</span>
            <div className="flex gap-2">
              <button
                onClick={() => onNavigate('previous')}
                disabled={totalItems <= 1}
                className="rounded-full p-2 hover:bg-gray-100 disabled:opacity-50"
              >
                <ArrowLeftIcon className="h-5 w-5 text-gray-500" />
              </button>
              <button
                onClick={() => onNavigate('next')}
                disabled={totalItems <= 1}
                className="rounded-full p-2 hover:bg-gray-100 disabled:opacity-50"
              >
                <ArrowRightIcon className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeatmapNavigation;