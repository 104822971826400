import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import axios from 'axios';

const isNumeric = (value) => {
  return /^\d+$/.test(value);
};

const fetchNameById = async (id, type, setBreadcrumbNames) => {
  try {
    const response = await axios.get(`https://panel.reislo.com/items/${type}/${id}`);
    setBreadcrumbNames(prev => ({ ...prev, [id]: response.data.data.name }));
  } catch (error) {
    console.error('Failed to fetch name:', error);
  }
};

export default function DynamicBreadcrumbs() {
  const location = useLocation();
  const [breadcrumbNames, setBreadcrumbNames] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter(x => x);
    let fetchPending = false;

    pathnames.forEach(async (segment, index) => {
      if (isNumeric(segment) && index > 0) { // Assuming the type is just before the ID
        const type = pathnames[index - 1];
        if (['personas', 'projects', 'userjourneys'].includes(type)) {
          if (!breadcrumbNames[segment]) { // Fetch if not already fetched
            fetchPending = true;
            await fetchNameById(segment, type, setBreadcrumbNames);
          }
        }
      }
    });

    if (!fetchPending) {
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (Object.keys(breadcrumbNames).length) {
      setLoading(false);
    }
  }, [breadcrumbNames]);

  const generateBreadcrumbName = (segment) => {
    if (breadcrumbNames[segment]) {
      return breadcrumbNames[segment];
    }
    return segment.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
  };

  const pathnames = location.pathname.split('/').filter(x => x);
  if (location.pathname === '/') {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <nav aria-label="Breadcrumb" className="pl-24">
      <ol className="flex items-center space-x-4 py-4">
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          return (
            <li key={to} className="flex items-center">
              {index > 0 && (
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              )}
              {last ? (
                <span className={`${index > 0 ? 'ml-4' : ''} text-sm font-medium text-gray-500 hover:text-gray-700`} aria-current="page">
                  {generateBreadcrumbName(value)}
                </span>
              ) : (
                <Link to={to} className={`${index > 0 ? 'ml-4' : ''} text-sm font-medium text-gray-500 hover:text-gray-700`}>
                  {generateBreadcrumbName(value)}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
