import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchHeatmapById, evaluateHeatmap, saveHeatmap, updateInsightCoordinates, updateInsight, refreshToken, deleteInsight } from './api';
import Snackbar from '../Atoms/snackbar';
import { ComputerDesktopIcon, DevicePhoneMobileIcon, ArrowLeftIcon, ArrowRightIcon, PencilIcon, FolderIcon } from '@heroicons/react/24/outline';
import { Menu, Transition, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { Switch } from '@headlessui/react';
import { ChatBubbleLeftIcon, LightBulbIcon, FlagIcon } from '@heroicons/react/24/outline';
import ImageMarker from 'react-image-marker';
import { MapPinIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import DrawerSingleItem from './DrawerSingleItem';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../api';
import Loader from './loader';
import HeatmapNavigation from './HeatmapNavigation';
import { format, formatDistanceToNow } from 'date-fns';
import BasicFrameworkSummary from './BasicFrameworkSummary';
import HeatmapOverlay from './HeatmapOverlay';

// Add this directusAPI configuration
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
});

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

const statusColors = {
  Good: 'border-green-500 bg-green-500',
  'Not Okay': 'border-yellow-500 bg-yellow-500',
  Bad: 'border-red-500 bg-red-500',
};

const categories = ["BEHAVIORAL", "ACCESSIBLE", "SUSTAINABLE", "INCLUSIVE", "CREDIBLE"];
const priorities = ["High", "Medium", "Low"];

const ProjectSelector = ({ currentProjectId, onChange }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await directusAPI.get('/items/projects', {
          params: {
            sort: '-date_created',
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return <div className="animate-pulse h-10 w-48 bg-gray-200 rounded"></div>;
  }

  return (
    <select
      value={currentProjectId || ''}
      onChange={(e) => onChange(e.target.value)}
      className=""
    >
      <option value="">Select a project</option>
      {projects.map((project) => (
        <option key={project.id} value={project.id}>
          {project.project_name}
        </option>
      ))}
    </select>
  );
};

const formatDateUpdated = (date) => {
  if (!date) return null;

  const now = new Date();
  const updatedDate = new Date(date);
  const diffInSeconds = Math.floor((now - updatedDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  }

  return formatDistanceToNow(updatedDate, { addSuffix: true });
};

const SingleHeatmap = () => {
  const { id } = useParams();
  const [heatmap, setHeatmap] = useState({
    name: '',
    screenshot: null,
    screenshot_mobile: null,
    coordinates_grid_screenshot: null,
    coordinates_grid_screenshot_mobile: null,
    evaluation_desktop: '',
    evaluation_mobile: '',
    project: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [showGrid, setShowGrid] = useState(false);
  const [viewMode, setViewMode] = useState('desktop');
  const [showHeatmap, setShowHeatmap] = useState(false);

  const handleSaveCategories = (newCategories) => {
    setSelectedCategories(newCategories);
  };

  const handleResetCategories = () => {
    setSelectedCategories([]);
  };

  const handleSavePriorities = (newPriorities) => {
    setSelectedPriorities(newPriorities);
  };

  const handleResetPriorities = () => {
    setSelectedPriorities([]);
  };


  const navigate = useNavigate();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isSettingMarker, setIsSettingMarker] = useState(false);

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const [isMouseInContent, setIsMouseInContent] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const [projectHeatmaps, setProjectHeatmaps] = useState([]);
  const [currentHeatmapIndex, setCurrentHeatmapIndex] = useState(0);

  const [projects, setProjects] = useState([]);

  const [lastSaved, setLastSaved] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await directusAPI.get('/items/projects', {
          params: {
            sort: '-date_created',
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchProjectHeatmaps = async () => {
      if (!heatmap?.project) return;

      try {
        const response = await directusAPI.get('/items/heatmaps', {
          params: {
            filter: {
              project: { _eq: heatmap.project }
            },
            sort: 'date_created'
          }
        });

        const heatmaps = response.data.data;
        setProjectHeatmaps(heatmaps);

        // Find current heatmap index
        const index = heatmaps.findIndex(h => h.id.toString() === id.toString());
        setCurrentHeatmapIndex(index !== -1 ? index : 0);

        console.log('Fetched heatmaps:', {
          total: heatmaps.length,
          currentId: id,
          currentIndex: index,
          allIds: heatmaps.map(h => h.id)
        });
      } catch (error) {
        console.error('Error fetching project heatmaps:', error);
      }
    };

    fetchProjectHeatmaps();
  }, [heatmap?.project, id]);

  const navigateToHeatmap = (direction) => {
    // Reset all states before navigation
    setDrawerOpen(false);
    setSelectedItem(null);
    setSelectedMarker(null);
    setIsSettingMarker(false);
    setHoveredMarker(null);

    if (!projectHeatmaps.length) return;

    let newIndex;
    if (direction === 'next') {
      newIndex = currentHeatmapIndex === projectHeatmaps.length - 1
        ? 0
        : currentHeatmapIndex + 1;
    } else {
      newIndex = currentHeatmapIndex === 0
        ? projectHeatmaps.length - 1
        : currentHeatmapIndex - 1;
    }

    const targetHeatmap = projectHeatmaps[newIndex];
    if (targetHeatmap?.id) {
      const newUrl = `/predictions/${targetHeatmap.id}`;
      if (window.location.pathname !== newUrl) {
        window.location.href = newUrl;
      } else {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    // Reset states when id changes
    setDrawerOpen(false);
    setSelectedItem(null);
    setSelectedMarker(null);
    setIsSettingMarker(false);
    setHoveredMarker(null);

    fetchHeatmapData();
  }, [id]);

  const fetchHeatmapData = async () => {
    setLoading(true);
    try {
      const response = await fetchHeatmapById(id);
      console.log('Raw API response:', response);

      const evaluation = response.evaluation;
      console.log('Raw evaluation data:', evaluation);

      let evaluation_desktop = parseEvaluation(evaluation);
      console.log('Parsed evaluation_desktop:', evaluation_desktop);

      // Process screenshots
      const screenshots = processScreenshots(response);

      // Ensure coordinates exist and are properly formatted
      const processedEvaluation_desktop = Array.isArray(evaluation_desktop)
        ? evaluation_desktop.map((item, index) => {
          console.log('Processing item:', item);
          return {
            ...item,
            coordinates: item.coordinates || { x: 50 + (index * 50), y: 50 },
            score_value: item.impact_score || item.score_value
          };
        })
        : [];

      console.log('Final processed evaluations:', processedEvaluation_desktop);

      setHeatmap({
        ...response,
        ...screenshots,
        evaluation_desktop: processedEvaluation_desktop,
        name: response.name || response.title || `Heatmap ${id}`
      });
    } catch (error) {
      console.error('Failed to fetch heatmap details:', error);
      setError('Failed to fetch heatmap details.');
    } finally {
      setLoading(false);
    }
  };

  const parseEvaluation = (evaluation) => {
    if (!evaluation) return [];

    let evaluationArray = [];

    if (typeof evaluation === 'string') {
      try {
        evaluationArray = JSON.parse(evaluation);
      } catch (e) {
        console.error('Error parsing evaluation:', e);
        return [];
      }
    } else if (Array.isArray(evaluation)) {
      evaluationArray = evaluation;
    } else if (evaluation && typeof evaluation === 'object') {
      evaluationArray = [evaluation];
    }

    return evaluationArray.map(item => {
      // Get score from score_value, defaulting to null if not present
      const scoreValue = item.score_value ?? null;
      return {
        id: item.id || Math.random().toString(36).substr(2, 9),
        title: item.Feature || item.title || '',
        insight_type: item.insight_type || 'BEHAVIORAL',
        insight_category: Array.isArray(item.insight_category) ? item.insight_category : [item.Category || 'Other'],
        priority: item.Priority || item.priority || 'Medium',
        content: item.Observation || item.content || '',
        recommendation: item.Recommendation || item.recommendation || '',
        coordinates: item.coordinates || { x: 0, y: 0 },
        status: item.Status || item.status || 'Needs Improvement',
        score_value: scoreValue // Use the actual score value from the database
      };
    });
  };

  // Helper function to map categories to insight types
  const mapCategoryToInsightType = (category) => {
    const typeMap = {
      'Visual Design': 'CREDIBLE',
      'Layout': 'SUSTAINABLE',
      'Content': 'CREDIBLE',
      'Accessibility': 'ACCESSIBLE',
      'Usability': 'BEHAVIORAL',
      'Default': 'BEHAVIORAL'
    };
    return typeMap[category] || typeMap.Default;
  };

  const mapInsightTypeToCategory = (insightType) => {
    const categoryMap = {
      'BEHAVIORAL': 'Usability',
      'ACCESSIBLE': 'Accessibility',
      'SUSTAINABLE': 'Layout',
      'INCLUSIVE': 'Content',
      'CREDIBLE': 'Visual Design'
    };
    return categoryMap[insightType] || 'Other';
  };

  const processScreenshots = (response) => {
    const supportedFormats = ['.jpeg', '.jpg', '.png', '.gif', '.webp'];
    const processUrl = (url) => {
      if (!url) return null;
      const fullUrl = `https://panel.reislo.com/assets/${url}`;
      return supportedFormats.some(format => fullUrl.endsWith(format)) ? fullUrl : `${fullUrl}.jpg`;
    };

    return {
      screenshot: processUrl(response.screenshot),
      screenshot_mobile: processUrl(response.screenshot_mobile),
      coordinates_grid_screenshot: processUrl(response.coordinates_grid_screenshot),
      coordinates_grid_screenshot_mobile: processUrl(response.coordinates_grid_screenshot_mobile),
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHeatmap((prev) => ({ ...prev, [name]: value }));
  };

  const handleEvaluate = async () => {
    setLoading(true);
    try {
      let screenshotUrl = viewMode === 'desktop'
        ? (heatmap.coordinates_grid_screenshot || heatmap.screenshot)
        : (heatmap.coordinates_grid_screenshot_mobile || heatmap.screenshot_mobile);

      if (!screenshotUrl) {
        throw new Error(`No ${viewMode} screenshot available for evaluation`);
      }

      screenshotUrl = screenshotUrl.split('/assets/').pop();

      // Get evaluation from OpenAI
      const evaluationResponse = await evaluateHeatmap({
        screenshot: screenshotUrl,
        name: heatmap.name,
        id: id,
        viewMode: viewMode,
        type: heatmap.type,
        linked_personas: heatmap.linked_personas
      });

      if (!evaluationResponse || !evaluationResponse.insights) {
        throw new Error('Invalid evaluation response received');
      }

      // Parse and process the new insights
      const newInsights = evaluationResponse.insights.map((insight, index) => ({
        id: Math.random().toString(36).substr(2, 9),
        title: insight.title,
        insight_type: insight.insight_type,
        insight_category: [insight.insight_type],
        priority: insight.priority,
        content: insight.description,
        recommendation: insight.recommendation,
        coordinates: { x: 50 + (index * 50), y: 50 },
        status: 'Needs Improvement',
        score_value: insight.impact_score ? parseInt(insight.impact_score) : 100
      }));

      // Combine existing insights with new ones
      const evaluationKey = viewMode === 'desktop' ? 'evaluation_desktop' : 'evaluation_mobile';
      const existingInsights = heatmap[evaluationKey] || [];
      const combinedInsights = [...existingInsights, ...newInsights];

      // Update local state
      setHeatmap(prev => ({
        ...prev,
        [evaluationKey]: combinedInsights
      }));

      try {
        // Save to backend
        await saveHeatmap(id, {
          ...heatmap,
          [evaluationKey]: JSON.stringify(combinedInsights)
        });

        setSnackbarMessage(`Added ${newInsights.length} new insights successfully`);
        setSnackbarType('success');
      } catch (saveError) {
        console.error('Save error:', saveError);
        setSnackbarMessage('New insights generated but failed to save');
        setSnackbarType('warning');
      }

      setShowSnackbar(true);

    } catch (error) {
      console.error('Error during evaluation:', error);
      setSnackbarMessage(error.message || `Failed to evaluate ${viewMode} heatmap`);
      setSnackbarType('error');
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    navigate('/predictions');
  };

  const handleSaveHeatmap = async () => {
    setLoading(true);
    try {
      const processedEvaluation = heatmap.evaluation_desktop.map(item => ({
        ...item,
        insight_category: Array.isArray(item.insight_category)
          ? item.insight_category
          : [item.insight_category || 'Other']
      }));

      const dataToSave = {
        id: id,
        name: heatmap.name || '',
        project: heatmap.project || null,
        evaluation: JSON.stringify(processedEvaluation),
        date_updated: new Date().toISOString()
      };

      const response = await saveHeatmap(id, dataToSave);
      setLastSaved(new Date().toISOString());
      setSnackbarMessage('Heatmap has been saved properly.');
      setSnackbarType('success');
      setShowSnackbar(true);
    } catch (error) {
      console.error('Error during save:', error);
      setSnackbarMessage('Failed to update heatmap');
      setSnackbarType('error');
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handlePriorityChange = (priority) => {
    setSelectedPriorities(prev =>
      prev.includes(priority)
        ? prev.filter(p => p !== priority)
        : [...prev, priority]
    );
  };


  const FilterDropdown = ({ title, options, selectedOptions, onChange, onSave, onReset }) => {
    const [localSelected, setLocalSelected] = useState(selectedOptions);

    const handleChange = (option) => {
      setLocalSelected(prev =>
        prev.includes(option) ? prev.filter(item => item !== option) : [...prev, option]
      );
    };

    const handleSave = () => {
      onSave(localSelected);
    };

    const handleReset = () => {
      setLocalSelected([]);
      onReset();
    };

    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {title}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items static className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options.map((option) => (
                <div key={option} className="px-4 py-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-primary-500 transition duration-150 ease-in-out"
                      checked={localSelected.includes(option)}
                      onChange={() => handleChange(option)}
                    />
                    <span className="ml-2 text-sm text-gray-700">{option}</span>
                  </label>
                </div>
              ))}
              <div className="border-t border-gray-100 px-4 py-2 flex justify-between">
                <button
                  onClick={handleSave}
                  className="text-sm text-primary-500 hover:text-primary-700"
                >
                  Filter
                </button>
                <button
                  onClick={handleReset}
                  className="text-sm text-neutral-600 hover:text-neutral-700"
                >
                  Reset
                </button>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const renderViewModeTabs = () => (
    <div className="pt-8">
      <div className="mb-8">
        <div className="grid grid-cols-12 gap-32">
          <div className="col-span-7">
            <h2 className="text-3xl font-medium mb-2">
              {heatmap.name || 'Untitled'}
              <button
                onClick={() => {
                  const newName = prompt('Enter new name:', heatmap.name);
                  if (newName) {
                    handleTitleChange(newName);
                  }
                }}
                className="p-1 hover:bg-gray-100 rounded-full ml-2">
                <PencilIcon className="h-5 w-5 text-gray-500" />
              </button>
            </h2>

            <div className="flex items-center gap-2 text-gray-600 mb-4">
              <FolderIcon className="h-5 w-5" />
              <span className="text-sm">Project:</span>
              <span className="text-sm font-medium">
                {projects.find(p => p.id === heatmap.project)?.project_name || 'None'}
              </span>
              <button
                onClick={() => {
                  setSelectedItem({ type: 'project-select', currentProject: heatmap.project });
                  setDrawerOpen(true);
                }}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <PencilIcon className="h-4 w-4 text-gray-500" />
              </button>
            </div>

            <p className="text-lg text-gray-600">
              Function now dynamically creates the filter dropdowns based on the available categories and priorities in the evaluation data.
            </p>
          </div>

          <div className="col-span-5">
            <BasicFrameworkSummary />
          </div>
        </div>
      </div>
      <div className="flex space-x-2 mt-4 mb-12">
        <button
          onClick={() => setViewMode('desktop')}
          className={`flex items-center space-x-2 px-4 py-2 rounded-md ${viewMode === 'desktop' ? 'bg-primary-100 text-primary-500' : 'text-gray-500'
            }`}
        >
          <ComputerDesktopIcon className="h-6 w-6" />
          <span>Desktop</span>
        </button>
        <button
          onClick={() => setViewMode('mobile')}
          className={`flex items-center space-x-2 px-4 py-2 rounded-md ${viewMode === 'mobile' ? 'bg-primary-100 text-primary-500' : 'text-gray-500'
            }`}
        >
          <DevicePhoneMobileIcon className="h-6 w-6" />
          <span>Mobile</span>
        </button>
      </div>
    </div>
  );

  const [isEditMode, setIsEditMode] = useState(false);

  const renderFilters = () => (
    <div className="flex">
      <div>
        <div className="mb-6 flex items-center space-x-4">
          <FilterDropdown
            title="Categories"
            options={categories}
            selectedOptions={selectedCategories}
            onChange={handleCategoryChange}
            onSave={handleSaveCategories}
            onReset={handleResetCategories}
          />
          <FilterDropdown
            title="Priorities"
            options={priorities}
            selectedOptions={selectedPriorities}
            onChange={handlePriorityChange}
            onSave={handleSavePriorities}
            onReset={handleResetPriorities}
          />
        </div>
        <div className="flex items-center mb-6 gap-4">
          <div className="flex items-center">
            <span className="mr-3 text-sm font-medium text-gray-900">G</span>
            <Switch
              checked={showGrid}
              onChange={setShowGrid}
              className={`${showGrid ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              <span className={`${showGrid ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`} />
            </Switch>
          </div>

          <div className="flex items-center">
            <span className="mr-3 text-sm font-medium text-gray-900">Edit Mode</span>
            <Switch
              checked={isEditMode}
              onChange={setIsEditMode}
              className={`${isEditMode ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              <span className={`${isEditMode ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`} />
            </Switch>
          </div>
          <div className="flex items-center">
            <span className="mr-3 text-sm font-medium text-gray-900">Heatmap</span>
            <Switch
              checked={showHeatmap}
              onChange={setShowHeatmap}
              className={`${showHeatmap ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              <span className={`${showHeatmap ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );

  const renderEvaluation = () => {
    const evaluation = viewMode === 'desktop' ? heatmap.evaluation_desktop : heatmap.evaluation_mobile;
    console.log('Rendering evaluation:', evaluation);

    // Add Evaluate button at the top
    return (
      <div>


        {/* Existing evaluation rendering code */}
        {!evaluation || evaluation.length === 0 ? (
          <div className="flex flex-col items-center justify-center p-12 text-center rounded-lg mt-16">
            <LightBulbIcon className="h-12 w-12 text-neutral-400 mb-4" />
            <h3 className="text-lg font-medium text-neutral-900 mb-2">No evaluation data available</h3>
            <p className="text-neutral-600 mb-4">Start by evaluating this heatmap</p>
          </div>
        ) : (
          <div className="">
            {renderFilters()}
            {evaluation.map((item, index) => {
              console.log('Rendering evaluation item:', item, 'Score value:', item.score_value);
              return (
                <div
                  key={item.id || index}
                  className={`w-full bg-white rounded-lg border flex-col justify-start items-start inline-flex overflow-hidden mb-4 ${hoveredMarker === index ? 'ring-2' : ''
                    }`}
                >
                  <div className={`self-stretch px-4 py-1 bg-${getStatusColor(item.status)} justify-start items-center gap-1.5 inline-flex`}>
                    <div className="text-white text-base font-medium leading-loose tracking-tight">
                      {index + 1}
                    </div>
                    <div className="grow shrink basis-0 text-white text-base font-medium leading-relaxed tracking-tight">
                      {item.title}
                    </div>
                    <div className="text-white text-sm font-medium bg-black/20 px-2 py-0.5 rounded">
                      Score: {item.score_value || 'N/A'}
                    </div>
                  </div>

                  <div className="self-stretch p-3 flex-col justify-start items-start gap-3 flex">
                    <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                      <div className="flex justify-between items-center w-full">
                        <div className="font-medium">
                          {item.insight_type}
                        </div>
                        <div className="text-secondary-500 text-sm">
                          Priority: {item.priority}
                        </div>
                      </div>
                      <div className="self-stretch text-neutral-700 text-sm font-normal">
                        {item.content}
                      </div>
                    </div>

                    {item.recommendation && (
                      <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className="self-stretch text-black text-sm leading-snug">
                          Recommendation
                        </div>
                        <div className="self-stretch text-neutral-700 text-sm leading-snug">
                          {item.recommendation}
                        </div>
                      </div>
                    )}

                    <div className="mt-2 flex gap-4">
                      {isEditMode && (
                        <button
                          onClick={() => {
                            setSelectedMarker(index);
                            setIsSettingMarker(true);
                          }}
                          className="text-sm rounded-md flex items-center gap-2"
                        >
                          <MapPinIcon className="h-4 w-4" />
                          Select Marker
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setSelectedItem(item);
                          setDrawerOpen(true);
                        }}
                        className="text-sm rounded-md flex items-center gap-2"
                      >
                        <InformationCircleIcon className="h-4 w-4" />
                        Details
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

<div className="mb-4">
          <button
            onClick={handleEvaluate}
            className="w-full inline-flex items-center justify-center px-4 py-2 rounded-md bg-primary-500 text-white hover:bg-primary-600 transition-colors"
            disabled={loading}
          >
            <LightBulbIcon className="h-5 w-5 mr-2" />
            {loading ? 'Evaluating...' : 'Add More Insights'}
          </button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const screenshotUrl = showGrid
      ? (viewMode === 'desktop' ? heatmap.coordinates_grid_screenshot : heatmap.coordinates_grid_screenshot_mobile)
      : (viewMode === 'desktop' ? heatmap.screenshot : heatmap.screenshot_mobile);

    if (!screenshotUrl) {
      return <p>No screenshot available for {viewMode} view</p>;
    }

    const evaluation = viewMode === 'desktop' ? heatmap.evaluation_desktop : heatmap.evaluation_mobile;
    let evaluationArray = [];

    if (typeof evaluation === 'string') {
      try {
        evaluationArray = JSON.parse(evaluation);
      } catch (e) {
        console.error('Error parsing evaluation:', e);
      }
    } else if (Array.isArray(evaluation)) {
      evaluationArray = evaluation;
    }

    // Ensure all items have coordinates
    evaluationArray = evaluationArray.map((item, index) => ({
      ...item,
      coordinates: item.coordinates || { x: 50 + (index * 50), y: 50 } // Default position if none exists
    }));

    const markers = evaluationArray.map((item, index) => ({
      top: item.coordinates.y,
      left: item.coordinates.x,
      itemNumber: index,
      id: item.id
    }));

    const CustomMarker = ({ itemNumber }) => {
      const handleMarkerClick = (e) => {
        if (!isEditMode) {
          const evaluation = viewMode === 'desktop' ? heatmap.evaluation_desktop : heatmap.evaluation_mobile;
          const item = evaluation[itemNumber];
          setSelectedItem(item);
          setDrawerOpen(true);
          return;
        }
      };

      if (!isEditMode) {
        return (
          <div
            role="button"
            tabIndex={0}
            onClick={handleMarkerClick}
            onMouseEnter={() => setHoveredMarker(itemNumber)}
            onMouseLeave={() => setHoveredMarker(null)}
            style={{
              position: 'absolute',
              zIndex: 1,
              pointerEvents: 'all',
              cursor: 'pointer',
            }}
            className={`w-6 h-6 rounded-full text-white flex items-center justify-center text-sm
              ${selectedMarker === itemNumber ? 'bg-primary-600 ring-2 ring-primary-300' : 'bg-primary-500'}
              ${hoveredMarker === itemNumber ? 'transform scale-110 ring-2 ring-primary-300' : ''}
              transition-all duration-150`}
          >
            {itemNumber + 1}
          </div>
        );
      }

      return (
        <Menu as="div" className="relative">
          <div
            className={`w-6 h-6 rounded-full text-white flex items-center justify-center text-sm
              ${selectedMarker === itemNumber ? 'bg-primary-600 ring-2 ring-primary-300' : 'bg-primary-500'}
              ${hoveredMarker === itemNumber ? 'transform scale-110 ring-2 ring-primary-300' : ''}
              transition-all duration-150`}
            style={{
              position: 'absolute',
              zIndex: 1,
              pointerEvents: 'all',
            }}
          >
            <span>{itemNumber + 1}</span>
            <Menu.Button className="absolute inset-0 w-full h-full flex items-center justify-center">
              <span className="sr-only">Open options</span>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      const evaluation = viewMode === 'desktop' ? heatmap.evaluation_desktop : heatmap.evaluation_mobile;
                      const item = evaluation[itemNumber];
                      setSelectedItem(item);
                      setDrawerOpen(true);
                    }}
                    className={`${active ? 'bg-gray-50' : ''
                      } block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left`}
                  >
                    Edit
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setSelectedMarker(itemNumber);
                      setIsSettingMarker(true);
                    }}
                    className={`${active ? 'bg-gray-50' : ''
                      } block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left`}
                  >
                    Move Pin
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      );
    };

    return (
      <div className="relative">
        <HeatmapOverlay
          imageUrl={screenshotUrl}
          isVisible={showHeatmap}
        />
        <ImageMarker
          src={screenshotUrl}
          markers={markers}
          onAddMarker={handleMarkerAdd}
          markerComponent={CustomMarker}
          className="absolute top-0 left-0 w-full h-full z-20"
        />
      </div>
    );
  };

  const handleMarkerAdd = async (marker) => {
    // If we're not in edit mode or not setting a marker, return early
    if (!isEditMode || !isSettingMarker || selectedMarker === null) {
      return;
    }

    try {
      const coordinates = {
        x: marker.left,
        y: marker.top
      };

      const evaluation = viewMode === 'desktop' ? heatmap.evaluation_desktop : heatmap.evaluation_mobile;
      const selectedInsight = evaluation[selectedMarker];

      if (!selectedInsight) {
        throw new Error(`No insight found at index ${selectedMarker}`);
      }

      // Update local state first for immediate feedback
      setHeatmap(prev => {
        const evaluationKey = viewMode === 'desktop' ? 'evaluation_desktop' : 'evaluation_mobile';
        return {
          ...prev,
          [evaluationKey]: prev[evaluationKey].map((item, index) =>
            index === selectedMarker
              ? { ...item, coordinates }
              : item
          )
        };
      });

      // Then update in backend
      await updateInsightCoordinates(selectedInsight.id, coordinates);

      setSnackbarMessage('Marker position updated successfully');
      setSnackbarType('success');
    } catch (error) {
      console.error('Error updating marker position:', error);
      setSnackbarMessage('Failed to update marker position');
      setSnackbarType('error');
    } finally {
      setShowSnackbar(true);
      setIsSettingMarker(false);
      setSelectedMarker(null);
      setHoveredMarker(null);
    }
  };

  const handleMouseMove = (e) => {
    if (isSettingMarker) {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleContentMouseEnter = () => {
    setIsMouseInContent(true);
  };

  const handleContentMouseLeave = () => {
    setIsMouseInContent(false);
  };

  const getStatusColor = (status) => {
    const colors = {
      'Good': 'green-500',
      'Needs Improvement': 'yellow-500',
      'Critical': 'red-500'
    };
    return colors[status] || 'gray-500';
  };

  const handleSaveInsight = async (editedInsight) => {
    setLoading(true);
    try {
      const formattedInsight = {
        status: editedInsight.status || 'published',
        insight_type: editedInsight.insight_type?.toLowerCase() || 'behavioral',
        piority: editedInsight.priority?.toLowerCase() || 'medium',
        title: editedInsight.title || null,
        description: editedInsight.content || '',
        recommendation: editedInsight.recommendation || '',
        heatmap: id,
        coordinates: JSON.stringify(editedInsight.coordinates || { x: 0, y: 0 }),
        score_value: editedInsight.score_value ? parseInt(editedInsight.score_value) : null,
        insight_category: editedInsight.insight_category || null,
        project: null,
        references: null
      };

      await updateInsight(editedInsight.id, formattedInsight);

      // Fetch fresh data
      const updatedData = await fetchHeatmapById(id);

      // Update local state with properly formatted data
      setHeatmap(prevHeatmap => ({
        ...prevHeatmap,
        evaluation_desktop: updatedData.evaluation.map(insight => ({
          id: insight.id,
          title: insight.title || insight.Feature,
          content: insight.description || insight.Observation,
          recommendation: insight.recommendation || insight.Recommendation,
          insight_type: insight.insight_type,
          priority: insight.piority || insight.Priority,
          status: insight.status || insight.Status,
          coordinates: insight.coordinates,
          score_value: insight.score_value,
          insight_category: insight.insight_category || null
        }))
      }));

      setLastSaved(new Date().toISOString());
      setSnackbarMessage('Insight updated successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
      setDrawerOpen(false);
    } catch (error) {
      console.error('Error saving insight:', error);
      setSnackbarMessage('Failed to update insight');
      setSnackbarType('error');
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedItem(null);
    setSelectedMarker(null);
    setIsSettingMarker(false);
    setHoveredMarker(null);
  };

  const handleDeleteInsight = async (insightToDelete) => {
    setLoading(true);
    try {
      await deleteInsight(insightToDelete.id);

      // Update local state by removing the deleted insight
      setHeatmap(prevHeatmap => {
        const evaluationKey = viewMode === 'desktop' ? 'evaluation_desktop' : 'evaluation_mobile';
        return {
          ...prevHeatmap,
          [evaluationKey]: prevHeatmap[evaluationKey].filter(insight => insight.id !== insightToDelete.id)
        };
      });

      setSnackbarMessage('Insight deleted successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
      setDrawerOpen(false);
    } catch (error) {
      console.error('Error deleting insight:', error);
      setSnackbarMessage('Failed to delete insight');
      setSnackbarType('error');
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTitleChange = async (newTitle) => {
    if (newTitle && newTitle !== heatmap.name) {
      setHeatmap(prev => ({
        ...prev,
        name: newTitle
      }));

      try {
        const processedEvaluation = heatmap.evaluation_desktop?.map(item => ({
          ...item,
          insight_category: Array.isArray(item.insight_category)
            ? item.insight_category
            : [item.insight_category || 'Other']
        })) || [];

        const dataToSave = {
          id: id,
          name: newTitle,
          project: heatmap.project || null,
          evaluation: JSON.stringify(processedEvaluation),
          date_updated: new Date().toISOString()
        };

        await saveHeatmap(id, dataToSave);
        setLastSaved(new Date().toISOString());
        setSnackbarMessage('Title updated successfully');
        setSnackbarType('success');
        setShowSnackbar(true);
      } catch (error) {
        console.error('Error saving title:', error);
        setSnackbarMessage('Failed to update title');
        setSnackbarType('error');
        setShowSnackbar(true);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div onMouseMove={handleMouseMove}>
      {isSettingMarker && isMouseInContent && (
        <div
          style={{
            position: 'fixed',
            left: cursorPosition.x + 20,
            top: cursorPosition.y + 20,
            zIndex: 1000,
            pointerEvents: 'none'
          }}
          className="bg-primary-500 text-white px-3 py-1 rounded-full text-sm"
        >
          Click on image to set marker
        </div>
      )}
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
      <div className="pb-40 lg:pl-0 bg-white w-full flex flex-col">
        <HeatmapNavigation
          onSave={handleSaveHeatmap}
          onEvaluate={handleEvaluate}
          onBack={goBack}
          currentIndex={currentHeatmapIndex}
          totalItems={projectHeatmaps.length}
          onNavigate={navigateToHeatmap}
          loading={loading}
          lastSaved={lastSaved ? formatDateUpdated(lastSaved) : null}
        />
        <div className="lg:pl-24 pt-2 lg:flex lg:justify-between bg-white pr-8">
          <div className="min-w-0 flex-1">
            {loading ? (
              <div className="animate-pulse">
                <div className="h-12 bg-gray-300 rounded w-3/4 mb-4"></div>
                <div className="h-6 bg-gray-300 rounded w-1/2 mb-2"></div>
              </div>
            ) : (
              <>
                {renderViewModeTabs()}
              </>
            )}
          </div>
        </div>

        <div className="bg-white relative border-t border-neutral-200">
          <div className="lg:pl-24 ">
            <div className="grid grid-cols-12 mt-0 gap-4 pr-8 bg-white">
              {loading ? (
                <div className="col-span-12 mt-12 bg-white rounded-xl animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-28 bg-gray-300 rounded mb-4"></div>
                </div>
              ) : (
                <>
                  <div className="col-span-4 mt-5">
                    {renderEvaluation()}
                  </div>
                  <div
                    className="evaluation-content col-span-8 relative bg-neutral-50 border-l border-neutral-200 py-16 px-16"
                    onMouseEnter={handleContentMouseEnter}
                    onMouseLeave={handleContentMouseLeave}
                  >

                    <div className="tooltip-wrapper border border-neutral-200 bg-white">
                      {renderContent()}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <DrawerSingleItem
          open={drawerOpen}
          setOpen={setDrawerOpen}
          item={selectedItem}
          onSave={handleSaveInsight}
          onDelete={handleDeleteInsight}
          isEditMode={isEditMode}
          className={`transform transition-transform duration-300 ease-in-out ${drawerOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
        />
      </div>
    </div>
  );
};



export default SingleHeatmap;