import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const BasicFrameworkSummary = () => {
  const frameworkData = {
    Behavioral: {
      score: 60,
      description: "Measures how well the design influences user behavior and decision-making"
    },
    Accessible: {
      score: 11,
      description: "Evaluates the design's usability for people with different abilities"
    },
    Sustainable: {
      score: 9,
      description: "Assesses the long-term viability and environmental impact of the design"
    },
    Inclusive: {
      score: 12,
      description: "Measures how well the design serves diverse user groups and perspectives"
    },
    Credible: {
      score: 8,
      description: "Evaluates the trustworthiness and reliability of the design"
    }
  };

  const getColorByPercentage = (percentage) => {
    if (percentage >= 80) return '#4ade80';
    if (percentage >= 60) return '#22c55e';
    if (percentage >= 40) return '#fbbf24';
    if (percentage >= 20) return '#f87171';
    return '#ef4444';
  };

  const createChartData = (value) => ({
    labels: ['Score'],
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [
          getColorByPercentage(value),
          '#f3f4f6'
        ],
        borderWidth: 0,
      },
    ],
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: () => ''
        }
      }
    },
    cutout: '70%'
  };

  return (
    <div className="bg-white rounded-lg pr-16">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Page Analysis</h3>
      <div className="flex justify-between">
        {Object.entries(frameworkData).map(([key, { score, description }]) => (
          <div key={key} className="flex flex-col items-center">
            <div className="h-16 w-16 relative group" title={description}>
              <Doughnut data={createChartData(score)} options={options} />
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="font-medium text-sm" style={{ color: getColorByPercentage(score) }}>
                  {score}%
                </span>
              </div>
              <div className="hidden group-hover:block absolute z-10 w-48 p-2 bg-gray-800 text-white text-xs rounded-lg -top-12 left-1/2 transform -translate-x-1/2">
                {description}
              </div>
            </div>
            <span className="text-sm text-gray-600 mt-2">{key}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BasicFrameworkSummary;