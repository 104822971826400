import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_USER_JOURNEYS_ENDPOINT, DIRECTUS_PROJECTS_ENDPOINT, DIRECTUS_PERSONAS_ENDPOINT } from '../../api';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';

function CreateUserJourney() {
  const navigate = useNavigate();
  const [journeyName, setJourneyName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const userID = localStorage.getItem('user_id');
  const token = localStorage.getItem('directus_token');
  const [personas, setPersonas] = useState([]);
  const [selectedPersonaIds, setSelectedPersonaIds] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userID }
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError('Failed to fetch projects');
      }
    };

    fetchProjects();
  }, [userID, token]);

  useEffect(() => {
    const fetchPersonas = async () => {
      try {
        const response = await axios.get(DIRECTUS_PERSONAS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userID },
            fields: ['id', 'name', 'avatar.id', 'type', 'occupation']
          }
        });
        setPersonas(response.data.data);
      } catch (error) {
        console.error('Error fetching personas:', error);
        setError('Failed to fetch personas');
      }
    };

    fetchPersonas();
  }, [userID, token]);

  const handleProjectChange = (event) => {
    const value = event.target.value;
    if (value === 'new-project') {
      navigate('/projects/create-project');
    } else {
      setSelectedProjectId(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!token) {
        throw new Error('Authentication token not found');
      }

      const config = {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      const newJourney = {
        user_journey_name: journeyName,
        short_description: description,
        status: 'published',
        owner: userID,
        linked_project: selectedProjectId,
        linked_personas: selectedPersonaIds.map(id => ({
          personas_id: id
        })),
        phase: [
          {
            phase_name: "Phase 1",
            emotional_curve: 0,
            painpoints: [],
            gains: [],
            order_value: 1
          },
          {
            phase_name: "Phase 2",
            emotional_curve: 0,
            painpoints: [],
            gains: [],
            order_value: 2
          },
          {
            phase_name: "Phase 3",
            emotional_curve: 0,
            painpoints: [],
            gains: [],
            order_value: 3
          }
        ]
      };

      const response = await axios.post(DIRECTUS_USER_JOURNEYS_ENDPOINT, newJourney, config);
      
      if (response.status === 200 && response.data?.data?.id) {
        navigate(`/user-journeys/${response.data.data.id}`);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error('Creation error:', err);
      const errorMessage = err.response?.data?.errors?.[0]?.message || 
                          err.response?.data?.message || 
                          err.message || 
                          'Server did not respond';
      setError(`Failed to create journey: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 lg:pl-24">
      <div className="max-w-2xl mx-auto">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h1 className="text-4xl font-medium leading-6 text-gray-900 mt-8">
                Create New User Journey
              </h1>
              <p className="mt-6 text-base text-gray-600">
                Start by giving your journey a name and description. You can add phases, painpoints, and gains later.
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="project" className="block text-sm font-medium text-gray-700">
                  Project <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <select
                    id="project"
                    value={selectedProjectId}
                    onChange={handleProjectChange}
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="">Select Project</option>
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.project_name}
                      </option>
                    ))}
                    <option value="new-project">Create New Project</option>
                  </select>
                </div>
              </div>

              <div>
                <label htmlFor="journeyName" className="block text-sm font-medium text-gray-700">
                  Journey Name <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="journeyName"
                    id="journeyName"
                    value={journeyName}
                    onChange={(e) => setJourneyName(e.target.value)}
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter journey name"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Short Description
                </label>
                <div className="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Describe your user journey"
                  />
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <label htmlFor="personas" className="block text-sm font-medium text-gray-700">
                    Linked Personas
                  </label>
                  <Menu as="div" className="relative mt-1">
                    <Menu.Button className="relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                      <span className="block truncate">
                        {selectedPersonaIds.length > 0 
                          ? `${selectedPersonaIds.length} personas selected`
                          : 'Select personas'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Menu.Button>

                    <Menu.Items className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {personas.map((persona) => (
                        <Menu.Item key={persona.id}>
                          {({ active }) => (
                            <div
                              className={`${
                                active ? 'bg-indigo-50' : ''
                              } relative cursor-pointer select-none py-2 pl-3 pr-9`}
                              onClick={() => {
                                setSelectedPersonaIds(prev => {
                                  const isSelected = prev.includes(persona.id);
                                  if (isSelected) {
                                    return prev.filter(id => id !== persona.id);
                                  } else {
                                    return [...prev, persona.id];
                                  }
                                });
                              }}
                            >
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={selectedPersonaIds.includes(persona.id)}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  onChange={() => {}}
                                />
                                <span className="ml-3 block truncate">
                                  {persona.name} - {persona.type}
                                </span>
                              </div>
                              {selectedPersonaIds.includes(persona.id) && (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                  <div className="mt-2 flex flex-wrap gap-2">
                    {selectedPersonaIds.map(id => {
                      const persona = personas.find(p => p.id === id);
                      return persona ? (
                        <span
                          key={persona.id}
                          className="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700"
                        >
                          {persona.name}
                          <button
                            type="button"
                            onClick={() => setSelectedPersonaIds(prev => prev.filter(pId => pId !== persona.id))}
                            className="ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
                          >
                            <span className="sr-only">Remove {persona.name}</span>
                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </button>
                        </span>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>

              {error && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">Error</h3>
                      <div className="mt-2 text-sm text-red-700">
                        <p>{error}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => navigate('/user-journeys')}
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading || !journeyName.trim() || !selectedProjectId}
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? 'Creating...' : 'Create Journey'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateUserJourney;
