import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_INSIGHTS_ENDPOINT } from '../../api';
import { ArrowLeftIcon, PencilIcon, MapIcon, ChartBarIcon, ExclamationTriangleIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { Bar } from 'react-chartjs-2';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import AutoSaveIndicator from '../Atoms/AutoSaveIndicator';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const ValueBar = ({ value }) => {
  const normalizedValue = Math.min(Math.max(value, 0), 100);
  
  return (
    <div className="flex items-center gap-2 w-full">
      <div className="relative flex-grow h-[6px] bg-gray-100 rounded">
        <div 
          className="absolute h-full bg-primary-500 rounded transition-all duration-500"
          style={{ width: `${normalizedValue}%` }}
        />
      </div>
      <span className="text-xs text-gray-600 whitespace-nowrap">
        {normalizedValue}%
      </span>
    </div>
  );
};

const PlaceholderCard = ({ type }) => (
  <div className="flex flex-col items-center justify-center p-8 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50">
    <ExclamationTriangleIcon className="h-8 w-8 text-gray-400 mb-2" />
    <p className="text-sm text-gray-500">No {type} linked to this insight</p>
  </div>
);

function InsightDetails() {
  const [insight, setInsight] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [descriptionEditor, setDescriptionEditor] = useState(null);
  const [recommendationEditor, setRecommendationEditor] = useState(null);
  const [comments, setComments] = useState([
    { id: 1, author: 'John Doe', content: 'This is a critical insight', date: new Date().toISOString() },
    { id: 2, author: 'Jane Smith', content: 'We should prioritize this', date: new Date().toISOString() }
  ]);

  // Business Value Chart Data
  const businessValueData = {
    labels: ['Current Value'],
    datasets: [{
      label: 'Business Value',
      data: [insight?.value || 75],
      backgroundColor: 'rgb(99, 102, 241)',
      borderRadius: 8,
    }]
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');

  useEffect(() => {
    const fetchInsight = async () => {
      try {
        const response = await axios.get(`${DIRECTUS_INSIGHTS_ENDPOINT}/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setInsight(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching insight details');
        setLoading(false);
      }
    };

    fetchInsight();
  }, [id, token]);

  useEffect(() => {
    if (insight) {
      // Initialize Description Editor
      const descEditor = new EditorJS({
        holder: 'description-editor',
        readOnly: true,
        data: (() => {
          try {
            if (insight.description?.startsWith('{')) {
              return JSON.parse(insight.description);
            }
            return {
              blocks: [{
                type: 'paragraph',
                data: {
                  text: insight.description || ''
                }
              }]
            };
          } catch {
            return {
              blocks: [{
                type: 'paragraph',
                data: {
                  text: insight.description || ''
                }
              }]
            };
          }
        })(),
        tools: {
          header: Header,
          list: List
        }
      });
      setDescriptionEditor(descEditor);

      // Initialize Recommendation Editor
      const recEditor = new EditorJS({
        holder: 'recommendation-editor',
        readOnly: true,
        data: (() => {
          try {
            return insight.recommendation ? JSON.parse(insight.recommendation) : {};
          } catch {
            return {
              blocks: [{
                type: 'paragraph',
                data: {
                  text: insight.recommendation || ''
                }
              }]
            };
          }
        })(),
        tools: {
          header: Header,
          list: List
        }
      });
      setRecommendationEditor(recEditor);
    }

    return () => {
      descriptionEditor?.destroy();
      recommendationEditor?.destroy();
    };
  }, [insight]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const getInsightTypeStyle = (type) => {
    const styles = {
      pain: 'text-red-700 bg-red-50 ring-red-600/20',
      gain: 'text-green-700 bg-green-50 ring-green-600/20',
    };
    return styles[type] || 'text-gray-700 bg-gray-50 ring-gray-600/20';
  };

  const getPriorityStyle = (priority) => {
    const styles = {
      high: 'text-red-700 bg-red-50',
      medium: 'text-yellow-700 bg-yellow-50',
      low: 'text-green-700 bg-green-50',
    };
    return styles[priority] || 'text-gray-700 bg-gray-50';
  };

  if (loading) return (
    <div className="animate-pulse p-8">
      <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    </div>
  );

  if (error) return <div className="text-red-500 p-8">{error}</div>;

  if (!insight) return <div className="p-8">No insight found</div>;

  return (
    <div className="lg:pl-24">
      {/* Header */}
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-b border-gray-200 pb-4 pt-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/insights')}
                className="mr-4 text-gray-400 hover:text-gray-500"
              >
                <ArrowLeftIcon className="h-6 w-6" />
              </button>
              <div>
                <h1 className="text-2xl font-semibold text-gray-900">
                  {insight?.title || 'Overlapping Elements test'}
                </h1>
                <p className="mt-1 text-sm text-gray-500">View and manage insight information</p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <AutoSaveIndicator isSaving={isSaving} />

            </div>
          </div>
        </div>

        {/* Main content grid */}
        <div className="mx-auto py-16">
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 lg:grid-cols-12">
            {/* Main content - 8 cols */}
            <div className="lg:col-span-8">
              <div className="space-y-10">
                {/* Description Section */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Description</h3>
                  <div id="description-editor" className="mt-2 prose max-w-none " />
                </div>

                {/* Recommendation Section */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Recommendation</h3>
                  <div id="recommendation-editor" className="mt-2 prose max-w-none" />
                </div>

                {/* User Journey Section */}
                <div>
                  <div className="flex items-center gap-2">
                    <MapIcon className="h-5 w-5 text-gray-400" />
                    <h3 className="text-lg font-medium text-gray-900">User Journey</h3>
                  </div>
                  <div className="mt-4">
                    {insight?.userjourney ? (
                      <Link 
                        to={`/journeys/${insight.userjourney}`} 
                        className="block p-4 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="text-gray-600">{insight.userjourney_title || 'View User Journey Details'}</p>
                            <p className="text-sm text-gray-500 mt-1">Click to view the full user journey</p>
                          </div>
                          <ArrowLeftIcon className="h-5 w-5 text-gray-400 transform rotate-180" />
                        </div>
                      </Link>
                    ) : (
                      <PlaceholderCard type="user journey" />
                    )}
                  </div>
                </div>

                {/* Heatmap Section */}
                <div>
                  <div className="flex items-center gap-2">
                    <ChartBarIcon className="h-5 w-5 text-gray-400" />
                    <h3 className="text-lg font-medium text-gray-900">Heatmap</h3>
                  </div>
                  <div className="mt-4">
                    {insight?.heatmap ? (
                      <Link 
                        to={`/predictions/${insight.heatmap}`} 
                        className="block p-4 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="text-gray-600">{insight.heatmap_title || 'View Heatmap Analysis'}</p>
                            <p className="text-sm text-gray-500 mt-1">Click to view the full heatmap analysis</p>
                          </div>
                          <ArrowLeftIcon className="h-5 w-5 text-gray-400 transform rotate-180" />
                        </div>
                      </Link>
                    ) : (
                      <PlaceholderCard type="heatmap" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Sidebar - 4 cols */}
            <div className="lg:col-span-4">
              <div className="space-y-6">
                {/* Business Value */}
                <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5 p-6">
                  <h3 className="text-sm font-medium text-gray-900">Business Value</h3>
                  <div className="mt-4">
                    <ValueBar value={insight?.value || 75} />
                  </div>
                </div>

                {/* Existing Info Card */}
                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                  <dl className="flex flex-wrap">
                    {/* Type */}
                    <div className="flex-auto px-6 py-4">
                      <dt className="text-sm font-medium text-gray-500">Type</dt>
                      <dd className="mt-1">
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${getInsightTypeStyle(insight?.insight_type)}`}>
                          {insight?.insight_type || 'behavioral'}
                        </span>
                      </dd>
                    </div>

                    {/* Score */}
                    <div className="flex-auto px-6 py-4">
                      <dt className="text-sm font-medium text-gray-500">Score</dt>
                      <dd className="mt-1">
                        <span className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium bg-gray-50 text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          {insight?.score_value || 0}
                        </span>
                      </dd>
                    </div>

                    {/* Priority */}
                    <div className="flex-auto px-6 py-4">
                      <dt className="text-sm font-medium text-gray-500">Priority</dt>
                      <dd className="mt-1">
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getPriorityStyle(insight?.piority)}`}>
                          {insight?.piority || 'high'}
                        </span>
                      </dd>
                    </div>

                    {/* Dates */}
                    <div className="w-full px-6 py-4">
                      <dt className="text-sm font-medium text-gray-500">Created</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {insight?.date_created ? formatDate(insight.date_created) : 'N/A'}
                      </dd>
                    </div>

                    {insight?.date_updated && (
                      <div className="w-full px-6 py-4 border-t border-gray-900/5">
                        <dt className="text-sm font-medium text-gray-500">Updated</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {formatDate(insight.date_updated)}
                        </dd>
                      </div>
                    )}
                  </dl>
                </div>

                {/* Comments Section */}
                <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5">
                  <div className="p-6">
                    <div className="flex items-center justify-between">
                      <h3 className="text-sm font-medium text-gray-900">Comments</h3>
                      <span className="text-xs text-gray-500">{comments.length}</span>
                    </div>
                    <div className="mt-4 space-y-4">
                      {comments.map(comment => (
                        <div key={comment.id} className="flex space-x-3">
                          <div className="flex-shrink-0">
                            <div className="h-8 w-8 rounded-full bg-gray-200" />
                          </div>
                          <div className="flex-1">
                            <div className="flex items-center justify-between">
                              <h4 className="text-sm font-medium text-gray-900">{comment.author}</h4>
                              <p className="text-xs text-gray-500">{new Date(comment.date).toLocaleDateString()}</p>
                            </div>
                            <p className="mt-1 text-sm text-gray-600">{comment.content}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mt-4">
                      <textarea
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Add a comment..."
                      />
                      <div className="mt-2 flex justify-end">
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                        >
                          Comment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsightDetails;