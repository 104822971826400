import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { DIRECTUS_PROJECTS_ENDPOINT } from '../../api';
import ContentHeader from '../Atoms/contentHeader';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, PlusIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const statuses = {
  published: 'text-green-700 bg-green-50 ring-green-600/20',
  draft: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// EmptyState component
const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <svg
      className="mx-auto h-16 w-16 text-primary-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
      />
    </svg>
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Let's start with new project</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new persona. Here soon will be cool marketing material.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New Project
      </button>
    </div>
  </div>
);

// Project skeleton loader component
const ProjectSkeleton = () => (
  <tr className="animate-pulse">
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-16"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-24"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-24"></div>
    </td>
  </tr>
);

function ProjectsTable() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');
  const ITEMS_PER_PAGE = 25;

  const title = 'Projects';
  const description = 'Navigate through the cards to view specific information or to make updates to existing projects.';
  const primaryAction = 'Create New Project';

  const primaryClick = () => navigate('/projects/create-project');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userId },
            limit: ITEMS_PER_PAGE,
            page: currentPage,
            sort: '-date_created',
            meta: 'total_count,filter_count',
            fields: '*,owner.first_name,owner.last_name'
          }
        });
        setProjects(response.data.data);
        const totalCount = response.data.meta.filter_count;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="lg:pl-24">
      <div className="mx-auto">
        {projects.length > 0 && !loading && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={primaryClick}
          />
        )}

        <div className="mt-8 flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Project Name</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Owner</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created Date</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Array.from({ length: 6 }).map((_, index) => (
                      <ProjectSkeleton key={index} />
                    ))}
                  </tbody>
                </table>
              ) : projects.length === 0 ? (
                <div className="col-span-full h-full flex items-center justify-center">
                  <EmptyState onPrimaryClick={primaryClick} />
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Project Name</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Owner</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created Date</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {projects.map((project) => (
                      <tr 
                        key={project.id} 
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => navigate(`/projects/${project.id}`)}
                      >
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {project.project_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          <span
                            className={classNames(
                              statuses[project.status] || 'text-gray-600 bg-gray-50 ring-gray-500/10',
                              'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                            )}
                          >
                            {project.status || 'N/A'}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {project.owner?.first_name 
                            ? `${project.owner.first_name} ${project.owner.last_name}`
                            : 'N/A'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {new Date(project.date_created).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <nav className="border-t border-gray-200 px-8 py-3 flex items-center justify-between sm:px-6">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative ml-3 inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> to{' '}
                <span className="font-medium">{Math.min(currentPage * ITEMS_PER_PAGE, projects.length)}</span> of{' '}
                <span className="font-medium">{totalPages * ITEMS_PER_PAGE}</span> results
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => handlePageChange(i + 1)}
                    className={classNames(
                      currentPage === i + 1
                        ? 'z-10 bg-primary-500 text-primary-500'
                        : 'text-gray-500 hover:bg-primary-50',
                      'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium'
                    )}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default ProjectsTable;