import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { DIRECTUS_INSTANCE } from './api'; // Adjust the import path as necessary
import axios from 'axios';
import Header from './components/Header/header';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';



/* Pinecone */ 
import Pinecone from './components/Pinecone/DataStorageManager';




/* General */ 
import Sidebar from './components/Sidebar/sidebar';
import Dashboard from './components/Dashboard/dashboard';
import UserProfile from './components/UserProfile/profile';
import PricingTable from './components/PricingTable/pricingTable';


/* Onboarding */ 
import FirstOnboarding from './components/Onboarding/onboarding';


/* Registration */
import SignIn from './components/Registration/signin';
import SignUp from './components/Registration/signup'; 


/* Projects */ 
import ProjectsTable from './components/Projects/projectsTable';
import ProjectDetails from './components/Projects/projectDetails';
import CreateProject from './components/Projects/createProject';


/* Personas */ 
import PersonasTable from './components/Personas/personasTable';
import PersonasForm from './components/Personas/createPersona';
import PersonaDetails from './components/Personas/personaDetails';
import PersonasSuccess from './components/Personas/personasSuccess';


/* Dataset */ 
import Datasets from './components/Datasets/datasets';
import CreateDatasets from './components/Datasets/createDataSet';


/* Heatmaps */ 
import Heatmaps from './components/HeatmapAnalysis/heatmapanalysis';
import SingleHeatmaps from './components/HeatmapAnalysis/singleHeatmap';
import CreateHeatmap from './components/HeatmapAnalysis/create-heatmap-analysis';

/* User Journeys */ 
import UserJourney from './components/UserJourney/userJourney';
import UserJourneyDetails from './components/UserJourney/userJourneyDetails';
import CreateUserJourney from './components/UserJourney/CreateUserJourney';


/* Articles */ 
import ArticlesTable from './components/ArticlesGenerator/articlesTable';
import Articles from './components/ArticlesGenerator/GenerateDraft/CreateArticleDraft';
import ArticleDraft from './components/ArticlesGenerator/SingleArticle/Article';

/* Insights */ 
import InsightsTable from './components/Insights/insights';
import InsightDetails from './components/Insights/InsightDetails';






import {
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  Bars3Icon,
  CalculatorIcon,
  CursorArrowRaysIcon,
  DocumentTextIcon,
  PresentationChartLineIcon,
  RocketLaunchIcon,

  TableCellsIcon
} from '@heroicons/react/24/outline'



const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const queryClient = new QueryClient();

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('directus_token') || '');

  useEffect(() => {
    const checkAuthentication = async () => {
      setIsAuthenticated(!!token);
      const tokenExpiryFromStorage = localStorage.getItem('directus_token_expiry');
      const currentTime = Math.floor(new Date().getTime() / 1000);

      if (!token && !['/signin', '/signup'].includes(location.pathname)) {
        navigate('/signin');
      } else if (tokenExpiryFromStorage && currentTime >= tokenExpiryFromStorage) {
        handleSignOut();
      } else if (token) {
        // Add check for onboarding status
        try {
          const response = await axios.get(`${DIRECTUS_INSTANCE}/users/me`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.data?.data && !response.data.data.onboarding_completed 
              && location.pathname !== '/onboarding') {
            navigate('/onboarding');
          }
        } catch (error) {
          console.error('Error checking onboarding status:', error);
          handleSignOut();
        }
      }
    };

    checkAuthentication();
    const tokenValidationInterval = setInterval(checkAuthentication, 60 * 1000);
    return () => clearInterval(tokenValidationInterval);
  }, [token, location.pathname]);

  const handleSignIn = async (newToken) => {
    localStorage.setItem('directus_token', newToken);
    const jwtPayload = JSON.parse(atob(newToken.split('.')[1]));
    const tokenExpiry = jwtPayload.exp;
    localStorage.setItem("directus_token_expiry", tokenExpiry)

    setToken(newToken);
    setIsAuthenticated(true);

    try {
      const response = await axios.get(`${DIRECTUS_INSTANCE}/users/me`, {
        headers: {
          'Authorization': `Bearer ${newToken}`,
        },
      });

      if (response.data && response.data.data) {
        const userData = response.data.data;
        localStorage.setItem('user_id', userData.id);
        
        // Check if onboarding is completed
        if (!userData.onboarding_completed) {
          navigate('/onboarding');
        } else {
          navigate('/');
        }
      } else {
        throw new Error('Failed to fetch user details');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      handleSignOut();
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    setToken('');
    setIsAuthenticated(false);
    navigate('/signin');
  };

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, current: location.pathname === '/' },
    { name: 'Projects', href: '/projects', icon: DocumentDuplicateIcon, current: location.pathname === '/projects' },
    { name: 'Personas', href: '/personas', icon: UsersIcon, current: location.pathname === '/personas' },
    { name: 'UX Analysis', href: '/predictions', icon: CursorArrowRaysIcon, current: location.pathname === '/predictions' },
    { name: 'User Journey', href: '/user-journeys', icon: PresentationChartLineIcon, current: location.pathname === '/user-journeys' },
    { name: 'Insights', href: '/insights', icon: RocketLaunchIcon, current: location.pathname === '/insights' },


    /*
    { name: 'Articles', href: '/articles', icon: DocumentTextIcon, current: location.pathname === '/articles' },
    { name: 'Datasets Library', href: '/datasets', icon: TableCellsIcon, current: location.pathname === '/datasets' },
    { name: 'Data Storage', href: '/data-storage', icon: TableCellsIcon, current: location.pathname === '/data-storage' },
     */
  
  ];

  const userNavigation = [
    { name: 'Your profile', href: '/profile' },
    { name: 'Sign out', href: '#', onClick: handleSignOut },
  ];

  // Update navigation items based on the current location
  const updatedNavigation = navigation.map(item => ({
    ...item,
    current: location.pathname === item.href
  }));

  return (
    <QueryClientProvider client={queryClient}>
      <div className="bg-white min-h-screen top-0">
        {isAuthenticated ? (
          <>
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              navigation={updatedNavigation}
              classNames={classNames}
              teams={teams}
              Cog6ToothIcon={Cog6ToothIcon}
            />
            <div className="">
              <Header
                setSidebarOpen={setSidebarOpen}
                userNavigation={userNavigation}
                classNames={classNames}
                Bars3Icon={Bars3Icon}
                onSignOut={handleSignOut}
              />
              <main className="">
                <div className="mx-auto w-full pl-4">
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/onboarding" element={<FirstOnboarding />} />
                    <Route path="/personas" element={<PersonasTable />} />
                    <Route path="/projects" element={<ProjectsTable />} />
                    <Route path="/projects/:id" element={<ProjectDetails />} />
                    <Route path="/projects/create-project" element={<CreateProject />} />
                    <Route path="/predictions/create-heatmap" element={<CreateHeatmap />} />
                    <Route path="/predictions" element={<Heatmaps />} />
                    <Route path="/predictions/:id" element={<SingleHeatmaps />} />
                    <Route path="/personas/create-persona" element={<PersonasForm />} />
                    <Route path="/personas/success" element={<PersonasSuccess />} />
                    <Route path="/personas/:id" element={<PersonaDetails />} />
                    <Route path="/profile" element={<UserProfile />} />
                    <Route path="/user-journeys" element={<UserJourney />} />
                    <Route path="/user-journeys/create" element={<CreateUserJourney />} />
                    <Route path="/user-journeys/:id" element={<UserJourneyDetails />} />
                    <Route path="/pricing" element={<PricingTable />} />
                    <Route path="/signup" element={<SignUp />} />  {/* New route for registration */}
                    <Route path="/datasets" element={<Datasets />} />  {/* New route for registration */}
                    <Route path="/datasets/create-dataset" element={<CreateDatasets />} />  {/* New route for registration */}
                    <Route path="/articles/create-article" element={<Articles />} />
                    <Route path="/articles/generate-draft" element={<ArticleDraft />} />
                    <Route path="/articles" element={<ArticlesTable />} />
                    <Route path="/articles/:id" element={<ArticleDraft />} />
                    <Route path="/data-storage" element={<Pinecone />} />                  
                    <Route path="/insights" element={<InsightsTable />} />
                    <Route path="/insights/:id" element={<InsightDetails />} />
                  </Routes>
                </div>
              </main>
            </div>
          </>
        ) : location.pathname === '/signup' ? (
          <SignUp onSignIn={handleSignIn} />
        ) : (
          <SignIn onSignIn={handleSignIn} />
        )}
      </div>
    </QueryClientProvider>
  );
}
