import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-webgl';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import { commonPatterns } from './eyeTrackingPatterns';

export class AIVisionSimulator {
  constructor() {
    this.objectDetector = null;
    this.faceLandmarksDetector = null;
    this.initialized = false;
    this.viewportHeight = window.innerHeight;
    this.scrollPosition = 0;
    this.currentPattern = null;
    this.lastTimestamp = 0;
  }

  async initialize() {
    if (!this.initialized) {
      await tf.setBackend('webgl');
      this.objectDetector = await cocoSsd.load();
      this.faceLandmarksDetector = await faceLandmarksDetection.createDetector(
        faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh
      );
      this.initialized = true;
    }
  }

  async analyzeImage(imageUrl) {
    const image = await this.loadImage(imageUrl);
    const [objectPredictions, facePredictions] = await Promise.all([
      this.detectObjects(image),
      this.detectFaceLandmarks(image)
    ]);

    return this.processDetections(
      objectPredictions, 
      facePredictions, 
      image.width, 
      image.height
    );
  }

  async detectObjects(image) {
    return await this.objectDetector.detect(image);
  }

  async detectFaceLandmarks(image) {
    return await this.faceLandmarksDetector.estimateFaces(image, {
      flipHorizontal: false,
      staticImageMode: true
    });
  }

  async simulateViewingSession(imageUrl, duration = 15000) {
    const analysis = await this.analyzeImage(imageUrl);
    const sequence = await this.generateViewingSequence(analysis, duration);
    return this.normalizeSequence(sequence, analysis.width, analysis.height);
  }

  async generateViewingSequence(analysis, duration) {
    const sequence = [];
    let currentTime = 0;
    const timeStep = 50; // 50ms per step

    while (currentTime < duration) {
      const eyePosition = this.getNextEyePosition(analysis, currentTime);
      const attention = this.calculateAttention(eyePosition, analysis.interestAreas);
      
      sequence.push({
        x: eyePosition.x,
        y: eyePosition.y,
        value: attention * 100,
        timestamp: currentTime
      });

      currentTime += timeStep;
    }

    return sequence;
  }

  getNextEyePosition(analysis, timestamp) {
    // Zmiana wzorca co 5 sekund
    if (timestamp - this.lastTimestamp > 5000 || !this.currentPattern) {
      this.currentPattern = this.selectPattern(analysis);
      this.lastTimestamp = timestamp;
    }

    const progress = ((timestamp - this.lastTimestamp) % 5000) / 5000;
    const basePosition = this.getPatternPosition(this.currentPattern, progress);
    
    // Dodaj mikroruchy i sakkady
    const noise = this.generateNoise(timestamp);
    return {
      x: basePosition.x + noise.x,
      y: basePosition.y + noise.y
    };
  }

  selectPattern(analysis) {
    const contentType = this.analyzeContentType(analysis);
    const patterns = commonPatterns.filter(p => p.weight > 0.5);
    
    return patterns[Math.floor(Math.random() * patterns.length)];
  }

  generateNoise(timestamp) {
    // Implementacja mikroruchu oczu (tremor)
    const frequency = timestamp / 1000; // 1Hz
    return {
      x: Math.sin(frequency) * 2,
      y: Math.cos(frequency) * 2
    };
  }

  calculateAttention(position, interestAreas) {
    let maxAttention = 0;

    for (const area of interestAreas) {
      const distance = this.calculateDistance(position, area.bbox);
      const attention = Math.exp(-distance) * area.priority;
      maxAttention = Math.max(maxAttention, attention);
    }

    return maxAttention;
  }

  calculateDistance(position, bbox) {
    const centerX = bbox.x + bbox.width / 2;
    const centerY = bbox.y + bbox.height / 2;
    
    return Math.sqrt(
      Math.pow(position.x - centerX, 2) + 
      Math.pow(position.y - centerY, 2)
    );
  }

  normalizeSequence(sequence, width, height) {
    return sequence.map(point => ({
      x: Math.round(point.x * width),
      y: Math.round(point.y * height),
      value: Math.round(point.value),
      timestamp: point.timestamp
    }));
  }
}