import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export function InsightViewDrawer({ isOpen, onClose, insight, phaseId }) {
  const [isAnimating, setIsAnimating] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
      // Small delay to ensure DOM is ready before animation
      const timer = setTimeout(() => {
        setIsAnimating(true);
      }, 30);
      return () => clearTimeout(timer);
    } else {
      setIsAnimating(false);
      // Wait for the animation to complete before removing from DOM
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300); // Match the duration of the transition
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsAnimating(false);
    // Wait for animation to complete before calling onClose
    setTimeout(onClose, 300);
  };

  if (!shouldRender || !insight) return null;

  return (
    <div 
      className={`fixed inset-0 overflow-hidden z-50 transition-all duration-300 ${
        isAnimating ? 'visible opacity-100' : 'invisible opacity-0'
      }`}
    >
      <div className="absolute inset-0 overflow-hidden">
        {/* Backdrop overlay */}
        <div 
          className={`absolute inset-0 bg-gray-500 transition-opacity duration-300 ease-in-out ${
            isAnimating ? 'opacity-75' : 'opacity-0'
          }`}
          onClick={handleClose}
        />

        {/* Drawer */}
        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <div 
            className={`relative w-96 transform transition-transform duration-300 ease-in-out ${
              isAnimating ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <div className="h-full flex flex-col bg-white shadow-xl">
              <div className="px-4 py-6 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <h2 className="text-lg font-medium">Insight Details</h2>
                  <button onClick={handleClose} className="text-gray-400 hover:text-gray-500">
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
              </div>

              <div className="flex-1 overflow-y-auto p-4">
                <div className="space-y-6">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Current Phase</h3>
                    <span className="mt-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium bg-blue-100 text-blue-700">
                      {phaseId ? `Phase ${phaseId}` : 'No Phase'}
                    </span>
                  </div>

                  {insight.description && (
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">Description</h3>
                      <p className="mt-2 text-sm text-gray-900">{insight.description}</p>
                    </div>
                  )}

                  {insight.recommendation && (
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">Recommendation</h3>
                      <p className="mt-2 text-sm text-gray-900">{insight.recommendation}</p>
                    </div>
                  )}

                  {insight.piority && (
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">Priority</h3>
                      <span className="mt-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium capitalize bg-gray-100 text-gray-700">
                        {insight.piority}
                      </span>
                    </div>
                  )}

                  {insight.insight_type && (
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">Type</h3>
                      <span className={`mt-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                        insight.insight_type === 'pain' 
                          ? 'bg-red-50 text-red-700' 
                          : 'bg-green-50 text-green-700'
                      }`}>
                        {insight.insight_type === 'pain' ? 'Pain' : 'Gain'}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 